import { $ } from "../third-party-imports";
import { bookingApiPayload } from "../scheduler/booking-api-payload";
import { bookingApiConfirmationScreen } from "../scheduler/booking-api-confirmation-screen";
import { getStateFun } from './getStateFun';
import { getFranchiseWeblocationID } from "./getFranchiseWeblocationID";
import { Api_Error_Msg } from "../config/constant";

export function irvingrewards()
{
    getStateFun();
    $('.irving-error-thankyou .js-next-btn').click(function(this:any) {
        irvingrewardsforminformation();
        $('.irving-reward-form').removeClass('d-none');
        $('.irving-error-thankyou').addClass('d-none');
    });
    $( $(`input[name='rewardoffer']`)).change(function (this: any)
    {
        IrVingreWardErrorMsg('rewardoffer','member-method');
    })
    $('#irvingrewardsform .submit-button').click(function (this: any)
    {
        window.objOSGlobal.requestBodyValues.irvingrewardsform = true;
        let memberMethod:any = IrVingreWardErrorMsg('rewardoffer','member-method');
        if ($('#irvingrewardsform').valid() && memberMethod==true)
        {
            irvingrewardsforminformation();
            getFranchiseWeblocationID(window.objOSGlobal.requestBodyValues.PostalCode, function (result: any)
            {
                if (result.length > 0)
                {
                    let WebLocationId: any = result[0].franchiseWebLocationId;
                    window.objOSGlobal.requestBodyValues.franchiseId = result[0].franchiseId;
                    window.objOSGlobal.requestBodyValues.WebLocationId = WebLocationId;
                    window.objOSGlobal.requestBodyValues.licenseNumber = result[0].licenseNumber;
                    window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
                    window.objOSGlobal.requestBodyValues.IsEstimate = false;
                    let url = window.DEFINE_VALUE.bookingEndpoint + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
                    window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.requestBodyValues.Note + ", Irving Rewards Member Offers:" + window.objOSGlobal.requestBodyValues.irvingRewardsMemberOffer;
                    window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.requestBodyValues.irvingComment !=="" ? window.objOSGlobal.requestBodyValues.Note + ", Anything you want us to know:" + window.objOSGlobal.requestBodyValues.irvingComment : window.objOSGlobal.requestBodyValues.Note;
                    let payload = bookingApiPayload();
                    bookingApiConfirmationScreen(payload, url, function (requesData: any)
                    {
                        let domainName = window.location.origin.toLowerCase();
                        let trailingSlash = localStorage.getItem("isTrailingSlash") == "true" ? "/" : "";
                        let websiteurl = $('#weblocationId').length ? domainName + "/" + $('#dbaName').val() + "/" : domainName;
                        let addressObj = requesData.Address2 != "" ? requesData.Address + ' ' + requesData.Address2 : requesData.Address;
                        let fulladdress = addressObj + '<br/>' + requesData.City + ', ' + requesData.State + ' ' + requesData.PostalCode;
                        sessionStorage.setItem('plg-name', requesData.FirstName + ' ' + requesData.LastName);
                        sessionStorage.setItem('plg-email', requesData.Email);
                        sessionStorage.setItem('plg-phone', requesData.Phone);
                        sessionStorage.setItem('plg-address', fulladdress);
                        sessionStorage.setItem('irvingRewardsMemberOffer',  window.objOSGlobal.requestBodyValues.irvingRewardsMemberOffer);
                        sessionStorage.setItem('comment', window.objOSGlobal.requestBodyValues.irvingComment);
                        sessionStorage.setItem('isIrvingForm',window.objOSGlobal.requestBodyValues.irvingrewardsform);
                        window.location.replace(websiteurl + "/" + "contact-thankyou" + trailingSlash);
                        
                    }, function (status: any)
                    {
                        $('.irving-reward-form').addClass('d-none');
                        $('.irving-error-thankyou').removeClass('d-none');
                        $('.card-title').html(`${Api_Error_Msg}`);
                    });
                }
                else
                {
                    $('.irving-reward-form').addClass('d-none');
                    $('.irving-error-thankyou').removeClass('d-none');
                    $('.card-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${window.objOSGlobal.requestBodyValues.PostalCode}</span>`);
                }
            });

        } else{
            $('.contact-information-screen').addClass('active');
        }
    })

    $('.rewards-form #inputZip').keypress(function(this: any, e: any){
        if (this.value === "0000" && e.which == 48){
        return false;
        }
    });
}
export function irvingrewardsforminformation()
{
    let firsName = $("#irvingrewardsform input[name='inputFirstName']").val();
    let lastName = $("#irvingrewardsform input[name='inputLastName']").val();
    let email = $("#irvingrewardsform input[name='inputEmail']").val();
    let phoneNumber = $("#irvingrewardsform input[name='inputPhnNumber']").val();
    let address = $("#irvingrewardsform input[name='inputStAdress']").val();
    let apartment = $("#irvingrewardsform input[name='apartment']").val();
    let city = $("#irvingrewardsform input[name='inputCity']").val();
    let state = $("#irvingrewardsform select[name='inputState']").val();
    let zipcode = $("#irvingrewardsform input[name='inputZip']").val();
    let comment = $("#irvingrewardsform #comment").val()?.trim();
    let irvingRewardsMemberOffer:any = [];
    $.each($("#irvingrewardsform input[name='rewardoffer']:checked"), function(this:any){            
        irvingRewardsMemberOffer.push($(this).val());
    });
    window.objOSGlobal.requestBodyValues.FirstName = firsName;
    window.objOSGlobal.requestBodyValues.LastName = lastName;
    window.objOSGlobal.requestBodyValues.Email = email.trim();
    window.objOSGlobal.requestBodyValues.Phone = phoneNumber;
    window.objOSGlobal.requestBodyValues.Address = address;
    window.objOSGlobal.requestBodyValues.Address2 = apartment;
    window.objOSGlobal.requestBodyValues.City = city;
    window.objOSGlobal.requestBodyValues.State = state;
    window.objOSGlobal.requestBodyValues.PostalCode = zipcode;
    window.objOSGlobal.requestBodyValues.irvingRewardsMemberOffer = irvingRewardsMemberOffer?.toString();
    window.objOSGlobal.requestBodyValues.ServiceText = "Irving Rewards";
    window.objOSGlobal.requestBodyValues.irvingComment = comment !==""? comment : "";
}

function IrVingreWardErrorMsg(name:any,cls:any) 
{ 
    return customErrorDisplay(name,cls);
}

function customErrorDisplay(name:any,cls:any){
    var fields = $(`input[name='${name}']`).serializeArray(); 
    if (fields.length === 0) 
    { 
        $(`.${cls} .custom-error`).remove();
        $(`.${cls}`).append('<span class="error-msg custom-error" style="display:inline">This field is required.</span>');
        return false;
    } 
    else 
    { 
        $(`.${cls} .custom-error`).remove();
        return true;
    }
}

export function irvinrewardFormConfirmationPage(){
    const plgName = sessionStorage.getItem('plg-name');
    const plgEmail = sessionStorage.getItem('plg-email');
    const plgPhone = sessionStorage.getItem('plg-phone');
    const plgAddress = sessionStorage.getItem('plg-address');
    const irvingRewardsMemberOffer = sessionStorage.getItem('irvingRewardsMemberOffer');
    const comment = sessionStorage.getItem('comment');
    const isIrvingForm = sessionStorage.getItem('isIrvingForm')
     
    $(".thankyou-name").text(plgName);
    $(".thankyou-email").text(plgEmail);
    $(".thankyou-phno").text(plgPhone);
    $(".thankyou-address1").html(plgAddress);
    if(isIrvingForm === 'true'){
        $(".thankyou-seloffer").text(irvingRewardsMemberOffer);
        if(comment !==""){
            $(".thankyou-comment").text(comment);
        }else{
            $(".thankyou-comment-list").addClass('d-none');
        }
    }else{
        if(comment !==""){
            $(".thankyou-comment").text(comment);
        }else{
            $(".thankyou-comment-list").addClass('d-none');
        }
        $(".thankyou-seloffer-list").addClass('d-none');   
    }
}