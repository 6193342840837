import { $ } from "../third-party-imports"
import {GetReferenceDetails} from '../util/GetReferenceDetails';
import { apiConfig } from "../config/apiConfig";
import { htmlInjectionField } from "../util/html-injection-field";
import { startLoader } from "../util/startLoader";
import { stopLoader } from "../util/stopLoader";
import { getBrandDetailsFromBrandJson } from "../util/util";

const objOSGlobal = window.objOSGlobal;
export function blogContactform() {
    if( $('#conceptCode').val() === "MRA"){
        addMRAFormItems()
    }
    GetReferenceDetails();
    setPhoneNumber();
    formatPhoneNumber();
    htmlInjectionField();
}
    
$('#blogContactForm .brand-btn').on('click', async function(e:any){
    e.preventDefault();
    if ($("#blogContactForm").valid()) {
            e.preventDefault();
            getFranchiseWebLocationId();
      }
    //submitContactForm();
});

function formatPhoneNumber() {
    var inputs = document.querySelectorAll("#blogContactForm input[name='blogInputPhone']");
    if (inputs)
    {
        for (var i = 0; i < inputs.length; i++)
        {
            var element = inputs[i];
            element.addEventListener('input', function (e:any)
            {
                var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            });
        }
    }
}

function setPhoneNumber() {
    try {
        let phone:any = $($(".anchor_desktop_phone_number").clone()[1]).children().remove().end().text().trim();
        $("#blog-phone-number").attr('href', 'tel:' + phone);
        $('#blog-phone-number').text(phone);
    }catch{}
}

function submitContactForm(WebLocationId:any) {
    
    let fName:any = $("#blogContactForm input[name='blogInputFirstNameTxt']").val();
    let lname:any = $("#blogContactForm input[name='blogInputLastNameTxt']").val();
    let email:any = $("#blogContactForm input[name='blogInputEmailAddress']").val();
    let phNum:any = $("#blogContactForm input[name='blogInputPhone']").val();
    let zipCode:any = $("#blogContactForm input[name='blogInputZip']").val();
    let comment:any = $("#blogContactForm textarea[name='blogInputComments']").val();
    let SignUpForUpdates =$("#blogContactForm input[name='blogReceiveUpdate']").prop('checked');
    let Api_URL:any = apiConfig.contactUSApi + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY;
    let IsTest:any = setIstestCondtion();
    let payload = {
        "FranchiseWebLocationId": WebLocationId,
        "IsLocalized": true,
        "FirstName": fName,
        "LastName": lname,
        "ZipCode": zipCode,
        "Email": email,
        "Phone": phNum,
        "IsNewCustomer": "",
        "Comments": comment,
        "SignUpForUpdates": SignUpForUpdates,
        "IsTest": IsTest,
        "IsLeadOnly":true
        // "ConceptId": Number($('#conceptId').val()) ? Number($('#conceptId').val()) : 0
     }
     const formdata = JSON.stringify(payload);
     console.log("ajax call start");
     let formdata = JSON.stringify(payload)
        $.ajax({
            url: Api_URL,
            type: "POST",
            dataType: 'json',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: formdata,
            success: function (result:any){
                stopLoader();
                $('#blogContactForm .form-row').addClass('d-none');
                $('#blogContactForm .thank-you').removeClass('d-none');
                $('.blog-list-section .contact-form .thank-you').css('display','block');
                let apiData = '{ "Result": '+ JSON.stringify(result) +', "Form data": '+ formdata +', "Response message": "SUCCESS" }';
                sessionStorage.setItem('NEI-API data',apiData);
                scrollToThankyouSection();
            },
            error: function (error:any)
            {
                stopLoader();
            }
     })
}

function getFranchiseWebLocationId() {
    let zipCode:any = $("#blogContactForm input[name='blogInputZip']").val();
    let ConceptId:any = Number($('#conceptId').val()) ? Number($('#conceptId').val()) : '';
    let apiUrl:any = `${window.DEFINE_VALUE.JS_UnifiedSyncPlatform_URL}/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress?apikey=${window.DEFINE_VALUE.JS_API_KEY}&Address=${zipCode}&IsRoundRobin=true&conceptID=${ConceptId}`;
    startLoader()
    $.ajax({
        type: "GET",
        url:apiUrl,
        contentType: "application/json",
        dataType: 'json',
        success: function(result:any){
            if(result?.length === 0){
                setInvalidZipCodeError(zipCode)
            }
                let WebLocationId:any = result[0].franchiseWebLocationId;
                window.objOSGlobal.requestBodyValues.franchiseId = result[0].franchiseId;
                window.objOSGlobal.requestBodyValues.WebLocationId = WebLocationId;
                window.objOSGlobal.requestBodyValues.licenseNumber = result[0].licenseNumber
                if($("#conceptCode").val() === 'FSP') {
                    submitContactForm(WebLocationId)
                } else {
                    callBrandJson();
                }    


        },
        error: function(data:any){
            stopLoader();
        }
    });
}
//Booking end point api ingeration starts from here
function requestBookingApi(gvendorId:any,gvendorName:any) {
    let conceptCode = $('#conceptCode').val()
    objOSGlobal.requestBodyValues.FirstName = $("#blogInputFirstNameTxt").val();
    objOSGlobal.requestBodyValues.LastName =  $("#blogInputLastNameTxt").val();
    objOSGlobal.requestBodyValues.Email = $("#blogInputEmailAddress").val();
    objOSGlobal.requestBodyValues.Phone = $("#blogInputPhone").val();
    objOSGlobal.requestBodyValues.PostalCode = $("#blogInputZip").val();
    objOSGlobal.requestBodyValues.City = $("#blogInputCityTxt").val();
    objOSGlobal.requestBodyValues.State = $("#blogInputState").val();
    objOSGlobal.requestBodyValues.Address = $("#blogInputAddressTxt").val();
    objOSGlobal.requestBodyValues.Address2 = $("#blogInputAppartmentTxt").val();
    objOSGlobal.requestBodyValues.Note = $("#blogInputComments").val();
    if(conceptCode.toLowerCase()==='mly'){
        objOSGlobal.requestBodyValues.ServiceText ="Lead Only";
    }
    else if(conceptCode.toLowerCase()==='mra'){
        objOSGlobal.requestBodyValues.ServiceText = "MRA Service Call";
    }else{
        objOSGlobal.requestBodyValues.ServiceText ="";
    }
    objOSGlobal.requestBodyValues.LeadSourceId =  conceptCode == 'MLY' ? $('#blogAboutUs option:selected').val() : objOSGlobal.requestBodyValues.LeadSourceId;
    if(conceptCode == 'MLY') {
    objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = objOSGlobal.requestBodyValues.LeadSourceId;
    } else {
        objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = null;
    }
    objOSGlobal.requestBodyValues.EstimateSquareFeet = $("#blogInputSquareFeetTxt").val();
    objOSGlobal.requestBodyValues.EstimateBedrooms = $('#blogBedroomsTxt option:selected').val();
    objOSGlobal.requestBodyValues.EstimateBathrooms = $('#blogBathroomsTxt option:selected').val();
    objOSGlobal.requestBodyValues.SignUpForUpdates = $("#blogContactForm input[name='blogReceiveUpdate']").prop('checked');
    objOSGlobal.requestBodyValues.ScheduleID = '';
    objOSGlobal.requestBodyValues.IsEstimate = false;
    objOSGlobal.requestBodyValues.ConceptName = "";
    const options:any = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }
    let JobPromisedDate:any = new Date().toLocaleDateString("en-US", options);
    GetCampaignName();
    objOSGlobal.requestBodyValues.IsTest = setIstestCondtion();
    if ($('#conceptCode').val() === "MRA"){
        window.objOSGlobal.requestBodyValues.Warranty = true;
    }else{
        window.objOSGlobal.requestBodyValues.Warranty = false;
    }
    if($("#appliance-waranty")[0]?.checked || $("#blogReceiveOffers")[0]?.checked){
        objOSGlobal.requestBodyValues.Note = objOSGlobal.requestBodyValues.Note.concat("\nWarranty\n");
    }
    
    var payload = {
        "FranchiseId": window.objOSGlobal.requestBodyValues.franchiseId ? window.objOSGlobal.requestBodyValues.franchiseId : 0,
        "HasSameDayScheduling": objOSGlobal.requestBodyValues.HasSameDayScheduling ? objOSGlobal.requestBodyValues.HasSameDayScheduling : false,
        "IsBetaTester": objOSGlobal.requestBodyValues.IsBetaTester ? objOSGlobal.requestBodyValues.IsBetaTester : false,
        "Comments": objOSGlobal.requestBodyValues.Comments ? objOSGlobal.requestBodyValues.Comments : "",
        "IsLocalized": objOSGlobal.requestBodyValues.IsLocalized ? objOSGlobal.requestBodyValues.IsLocalized : true,
        "ConceptId": Number($('#conceptId').val()) ? Number($('#conceptId').val()) : 0,
        "UseFranchiseProfileOptInForPOSScheduling": objOSGlobal.requestBodyValues.UseFranchiseProfileOptInForPOSScheduling ? objOSGlobal.requestBodyValues.UseFranchiseProfileOptInForPOSScheduling : true,
        "IsFranchiseOptedIntoPOSScheduling": objOSGlobal.requestBodyValues.IsFranchiseOptedIntoPOSScheduling ? objOSGlobal.requestBodyValues.IsFranchiseOptedIntoPOSScheduling : true,
        "C4ChannelTypeId": objOSGlobal.requestBodyValues.C4ChannelTypeId ? objOSGlobal.requestBodyValues.C4ChannelTypeId : 0,
        "ConceptCalledId": Number($('#conceptId').val()) ? Number($('#conceptId').val()) : 0,
        "ConceptName": objOSGlobal.requestBodyValues.ConceptName ? objOSGlobal.requestBodyValues.ConceptName : "",
        "ConceptCode": $('#conceptCode').val() ? $('#conceptCode').val() : "",
        "C4LeadsEnabled": objOSGlobal.requestBodyValues.C4LeadsEnabled ? objOSGlobal.requestBodyValues.C4LeadsEnabled : true,
        "CanceledJobInformation": objOSGlobal.requestBodyValues.CanceledJobInformation ? objOSGlobal.requestBodyValues.CanceledJobInformation : "",
        "VendorId": gvendorId ? gvendorId : "",
        "NationalLeadId": objOSGlobal.requestBodyValues.NationalLeadId ? objOSGlobal.requestBodyValues.NationalLeadId : "",
        "IsTest": objOSGlobal.requestBodyValues.IsTest,
        "VendorName": gvendorName ? gvendorName : "",
        "WebLocationId": window.objOSGlobal.requestBodyValues.WebLocationId ? Number(window.objOSGlobal.requestBodyValues.WebLocationId) : 0,
        "C4CallCenterId": objOSGlobal.requestBodyValues.C4CallCenterId ? objOSGlobal.requestBodyValues.C4CallCenterId : 0,
        "DayTimePhoneNumber": objOSGlobal.requestBodyValues.DayTimePhoneNumber ? objOSGlobal.requestBodyValues.DayTimePhoneNumber : "",
        "ZipCode": objOSGlobal.requestBodyValues.ZipCode ? objOSGlobal.requestBodyValues.ZipCode : "",
        "Note": objOSGlobal.requestBodyValues.Note ? objOSGlobal.requestBodyValues.Note : "",
        "FirstName": objOSGlobal.requestBodyValues.FirstName ? objOSGlobal.requestBodyValues.FirstName : "",
        "LastName": objOSGlobal.requestBodyValues.LastName ? objOSGlobal.requestBodyValues.LastName : "",
        "Email": objOSGlobal.requestBodyValues.Email ? objOSGlobal.requestBodyValues.Email.trim() : "",
        "Phone": objOSGlobal.requestBodyValues.Phone ? objOSGlobal.requestBodyValues.Phone : "",
        "PhoneType": objOSGlobal.requestBodyValues.PhoneType ? objOSGlobal.requestBodyValues.PhoneType : "",
        "PostalCode": objOSGlobal.requestBodyValues.PostalCode ? objOSGlobal.requestBodyValues.PostalCode : "",
        "City": objOSGlobal.requestBodyValues.City ? objOSGlobal.requestBodyValues.City : "",
        "State": objOSGlobal.requestBodyValues.State ? objOSGlobal.requestBodyValues.State : "",
        "Country": $("#countryCode").val() ? $("#countryCode").val().toUpperCase() : "",
        "Address": objOSGlobal.requestBodyValues.Address ? objOSGlobal.requestBodyValues.Address : "",
        "Address2": objOSGlobal.requestBodyValues.Address2 ? objOSGlobal.requestBodyValues.Address2 : "",
        "LeadSource": objOSGlobal.requestBodyValues.LeadSource ? objOSGlobal.requestBodyValues.LeadSource : "Web",
        "LicenseNumber":  window.objOSGlobal.requestBodyValues.licenseNumber ? window.objOSGlobal.requestBodyValues.licenseNumber : "",
        "WebLocationAccountCode": objOSGlobal.requestBodyValues.WebLocationAccountCode ? objOSGlobal.requestBodyValues.WebLocationAccountCode : "",
        "LeadOrigin": objOSGlobal.requestBodyValues.LeadOrigin ? objOSGlobal.requestBodyValues.LeadOrigin : "Web",
        "CampaignName": objOSGlobal.requestBodyValues.CampaignName ? objOSGlobal.requestBodyValues.CampaignName : "",
        "ReferringURL": document.referrer,
        "CurrentPage":  window.location.hostname.toLowerCase(),
        "Disposition": objOSGlobal.requestBodyValues.Disposition ? objOSGlobal.requestBodyValues.Disposition : "",
        "CallType": objOSGlobal.requestBodyValues.CallType ? objOSGlobal.requestBodyValues.CallType : "",
        "EmailOptOut": objOSGlobal.requestBodyValues.EmailOptOut ? objOSGlobal.requestBodyValues.EmailOptOut : true,
        "TagName": objOSGlobal.requestBodyValues.TagName ? objOSGlobal.requestBodyValues.TagName : "",
        "Latitude": objOSGlobal.requestBodyValues.Latitude ? objOSGlobal.requestBodyValues.Latitude : 0,
        "Longitude": objOSGlobal.requestBodyValues.Longitude ? objOSGlobal.requestBodyValues.Longitude : 0,
        "LocationType": objOSGlobal.requestBodyValues.LocationType ? objOSGlobal.requestBodyValues.LocationType : "",
        "IsGoogleAddress": objOSGlobal.requestBodyValues.IsGoogleAddress ? objOSGlobal.requestBodyValues.IsGoogleAddress : true,
        "GoogleCalendarEventId": objOSGlobal.requestBodyValues.GoogleCalendarEventId ? objOSGlobal.requestBodyValues.GoogleCalendarEventId : "",
        "GoogleCalendarEmail": objOSGlobal.requestBodyValues.GoogleCalendarEmail ? objOSGlobal.requestBodyValues.GoogleCalendarEmail : "",
        "ServiceText": objOSGlobal.requestBodyValues.ServiceText ? objOSGlobal.requestBodyValues.ServiceText : "",
        "ServiceID": objOSGlobal.requestBodyValues.ServiceID ? objOSGlobal.requestBodyValues.ServiceID : 0,
        "Warranty": objOSGlobal.requestBodyValues.Warranty,
        "TimeSlotTypeId": objOSGlobal.requestBodyValues.TimeSlotTypeId ? objOSGlobal.requestBodyValues.TimeSlotTypeId : 0,
        "MakeId": objOSGlobal.requestBodyValues.MakeId ? objOSGlobal.requestBodyValues.MakeId : 133,
        "LeadSourceId": objOSGlobal.requestBodyValues.LeadSourceId ? objOSGlobal.requestBodyValues.LeadSourceId : 0,
        "SecondaryLeadSourceId": objOSGlobal.requestBodyValues.SecondaryLeadSourceId ? objOSGlobal.requestBodyValues.SecondaryLeadSourceId : 0,
        "ServiceTypeProblem": objOSGlobal.requestBodyValues.ServiceTypeProblem ? objOSGlobal.requestBodyValues.ServiceTypeProblem : "",
        "OverrideJobPromisedDate": objOSGlobal.requestBodyValues.OverrideJobPromisedDate ? objOSGlobal.requestBodyValues.OverrideJobPromisedDate : "",
        "ServiceSkuId": objOSGlobal.requestBodyValues.serviceSkuId ? objOSGlobal.requestBodyValues.serviceSkuId :conceptCode == 'MRA'? 7989: 0,
        "ServiceSkuIds": objOSGlobal.requestBodyValues.ServiceSkuIds ? objOSGlobal.requestBodyValues.ServiceSkuIds : [conceptCode == 'MRA'? 7989: 0],
        "IsEstimate": objOSGlobal.requestBodyValues.IsEstimate,
        "EstimateHouseStyle": objOSGlobal.requestBodyValues.EstimateHouseStyle ? objOSGlobal.requestBodyValues.EstimateHouseStyle : "",
        "EstimateSquareFeet": objOSGlobal.requestBodyValues.EstimateSquareFeet ? objOSGlobal.requestBodyValues.EstimateSquareFeet : "",
        "EstimateBedrooms": objOSGlobal.requestBodyValues.EstimateBedrooms ? objOSGlobal.requestBodyValues.EstimateBedrooms : "",
        "EstimateBathrooms": objOSGlobal.requestBodyValues.EstimateBathrooms ? objOSGlobal.requestBodyValues.EstimateBathrooms : "",
        "EstimatePets": objOSGlobal.requestBodyValues.EstimatePets ? objOSGlobal.requestBodyValues.EstimatePets : "",
        "CCFirstName": objOSGlobal.requestBodyValues.CCFirstName ? objOSGlobal.requestBodyValues.CCFirstName : "",
        "CCLastName": objOSGlobal.requestBodyValues.CCLastName ? objOSGlobal.requestBodyValues.CCLastName : "",
        "CCType": objOSGlobal.requestBodyValues.CCType ? objOSGlobal.requestBodyValues.CCType : "",
        "CCSuffix": objOSGlobal.requestBodyValues.CCSuffix ? objOSGlobal.requestBodyValues.CCSuffix : "",
        "CCToken": objOSGlobal.requestBodyValues.CCToken ? objOSGlobal.requestBodyValues.CCToken : "",
        "CCExpirationDate": objOSGlobal.requestBodyValues.CCExpirationDate ? objOSGlobal.requestBodyValues.CCExpirationDate : "",
        "CCComment": objOSGlobal.requestBodyValues.CCComment ? objOSGlobal.requestBodyValues.CCComment : "",
        "IsLeadOnly": true,
        "TimeBlock": {
            "ConceptId": Number($('#conceptId').val()) ? Number($('#conceptId').val()) : 0,
            "GoogleCalendarEventId": objOSGlobal.requestBodyValues.GoogleCalendarEventId ? objOSGlobal.requestBodyValues.GoogleCalendarEventId : "",
            "GoogleCalendarEmail": objOSGlobal.requestBodyValues.GoogleCalendarEmail ? objOSGlobal.requestBodyValues.GoogleCalendarEmail : "",
            "TimeSlotTypeId": objOSGlobal.requestBodyValues.TimeSlotTypeId ? objOSGlobal.requestBodyValues.TimeSlotTypeId : 0,
            "ServiceSkuId": objOSGlobal.requestBodyValues.serviceSkuId ? objOSGlobal.requestBodyValues.serviceSkuId : 0,
            "IsEstimate": objOSGlobal.requestBodyValues.IsEstimate,
            "JobPromisedDate": JobPromisedDate ? JobPromisedDate : "",
            "IsAvailable": objOSGlobal.requestBodyValues.IsAvailable ? objOSGlobal.requestBodyValues.IsAvailable : true,
            "IsFranchiseInPOS": objOSGlobal.requestBodyValues.IsFranchiseInPOS ? objOSGlobal.requestBodyValues.IsFranchiseInPOS : true,
            "DisplayText": objOSGlobal.requestBodyValues.DisplayText ? objOSGlobal.requestBodyValues.DisplayText : ""
        },
        "OverrideCSRName": objOSGlobal.requestBodyValues.OverrideCSRName ? objOSGlobal.requestBodyValues.OverrideCSRName : "",
        "OverrideComment": objOSGlobal.requestBodyValues.OverrideComment ? objOSGlobal.requestBodyValues.OverrideComment : "",
        "SyncTenantName": objOSGlobal.requestBodyValues.SyncTenantName ? objOSGlobal.requestBodyValues.SyncTenantName : "",
        "IsEmergencyCall": objOSGlobal.requestBodyValues.IsEmergencyCall ? objOSGlobal.requestBodyValues.IsEmergencyCall : false,
        "CallId": objOSGlobal.requestBodyValues.CallId ? objOSGlobal.requestBodyValues.CallId : "",
        "IsFranchiseInPOS": objOSGlobal.requestBodyValues.IsFranchiseInPOS ? objOSGlobal.requestBodyValues.IsFranchiseInPOS : true,
        "CustomerType": objOSGlobal.requestBodyValues.CustomerType ? objOSGlobal.requestBodyValues.CustomerType : "",
        "NeighborlyAccountId": objOSGlobal.requestBodyValues.NeighborlyAccountId ? objOSGlobal.requestBodyValues.NeighborlyAccountId : 0,
        "NeighborlyLeadSourceId": objOSGlobal.requestBodyValues.NeighborlyLeadSourceId ? objOSGlobal.requestBodyValues.NeighborlyLeadSourceId : "",
        "ReferralTypeId": objOSGlobal.requestBodyValues.ReferralTypeId ? objOSGlobal.requestBodyValues.ReferralTypeId : null,
        "ReferralType": objOSGlobal.requestBodyValues.ReferralType ? objOSGlobal.requestBodyValues.ReferralType : null ,
        "FileUrls": [{
            "FileUrl": ""
        }, {
            "FileUrl": ""
        }, {
            "FileUrl": ""
        }],
        "JobFrequency": objOSGlobal.requestBodyValues.JobFrequency ? objOSGlobal.requestBodyValues.JobFrequency : "",
        "JobFrequencyDetail": objOSGlobal.requestBodyValues.JobFrequencyDetail ? objOSGlobal.requestBodyValues.JobFrequencyDetail : "",
        "Year": objOSGlobal.requestBodyValues.Year ? objOSGlobal.requestBodyValues.Year : 0,
        "Make": objOSGlobal.requestBodyValues.Make ? objOSGlobal.requestBodyValues.Make : "",
        "Model": objOSGlobal.requestBodyValues.Model ? objOSGlobal.requestBodyValues.Model : "",
        "BodyStyle": objOSGlobal.requestBodyValues.BodyStyle ? objOSGlobal.requestBodyValues.BodyStyle : "",
        "VIN": objOSGlobal.requestBodyValues.VIN ? objOSGlobal.requestBodyValues.VIN : "",
        "LicensePlate": objOSGlobal.requestBodyValues.LicensePlate ? objOSGlobal.requestBodyValues.LicensePlate : "",
        "WindshieldRepair": objOSGlobal.requestBodyValues.WindshieldRepair ? objOSGlobal.requestBodyValues.WindshieldRepair : "",
        "HeadlightRestoration": objOSGlobal.requestBodyValues.HeadlightRestoration ? objOSGlobal.requestBodyValues.HeadlightRestoration : "",
        "ServiceRequested": [] as any[],
        "LeadAdditionalNotes": {
            "LeadAdditionalNoteLabel": objOSGlobal.requestBodyValues.LeadAdditionalNoteLabel ? objOSGlobal.requestBodyValues.LeadAdditionalNoteLabel : "",
            "LeadAdditionalNoteText": objOSGlobal.requestBodyValues.LeadAdditionalNoteText ? objOSGlobal.requestBodyValues.LeadAdditionalNoteText : ""
        },
        "FormOfPayment": objOSGlobal.requestBodyValues.FormOfPayment ? objOSGlobal.requestBodyValues.FormOfPayment : "",
        "InsuranceCompany": objOSGlobal.requestBodyValues.InsuranceCompany ? objOSGlobal.requestBodyValues.InsuranceCompany : "",
        "DeductibleAmount": objOSGlobal.requestBodyValues.DeductibleAmount ? objOSGlobal.requestBodyValues.DeductibleAmount : 0,
        "PolicyNumber": objOSGlobal.requestBodyValues.PolicyNumber ? objOSGlobal.requestBodyValues.PolicyNumber : "",
        "ScheduleID": window.objOSGlobal.requestBodyValues.ScheduleId ? window.objOSGlobal.requestBodyValues.ScheduleId : "",
        "SignUpForUpdates":objOSGlobal.requestBodyValues.SignUpForUpdates,
        "businessName":"",
        "serviceTypeID":0,
    };
    startLoader();
    let formdata = JSON.stringify(payload);
    $.ajax({
        url: window.DEFINE_VALUE.bookingEndpoint + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY,
        type: "POST",
        contentType: "application/json",
        dataType: "json",
        data: formdata,
        success: function (data:any, status:any){
            stopLoader();
            localStorage.setItem("OSWebLocationId", window.objOSGlobal.requestBodyValues.WebLocationId);
            localStorage.setItem("OSDbaFullName", window.objOSGlobal.paramsUI.doingBusinessAs);
            
            if( $('#conceptCode').val()  == 'MRA'){
                setThanksMessage()
            }
            $('#blogContactForm .form-row').addClass('d-none');
            $('#blogContactForm .thank-you').removeClass('d-none');
            $('.blog-list-section .contact-form .thank-you').css('display','block');
            let apiData = '{ "Result": '+ JSON.stringify(data) +', "Form data": '+ formdata +', "Response message": "SUCCESS" }';
            sessionStorage.setItem('NEI-API data',apiData);
            scrollToThankyouSection();  
        },
        error: function (error:any,status:any){ 
            stopLoader(); 
        }
    })
}
async function callBrandJson()
{
    // let brands = $("#countryCode").val() == 'ca' ? "/ca/en-ca/_assets/brands.json" : "/us/en-us/_assets/brands.json";
    // $.getJSON(brands, function (result: any){
    //     if (result.length > 0){
    //         var items = $.grep(result, function (e: any) { return e.code === $('#conceptCode').val() });
    //         let gvendorId:any = items[0].vendorId ? items[0].vendorId : "";
    //         let gvendorName:any = items[0].vendorName ? items[0].vendorName : "";
    //         requestBookingApi(gvendorId,gvendorName);
    //     }
    // });

    const result = await getBrandDetailsFromBrandJson();
    if (result.length > 0)
    {
        var items = $.grep(result, function (e: any) { return e.code === $('#conceptCode').val() });
        let gvendorId: any = items[0].vendorId ? items[0].vendorId : "";
        let gvendorName: any = items[0].vendorName ? items[0].vendorName : "";
        requestBookingApi(gvendorId, gvendorName);
    }
}

function GetCampaignName()
{
    let url = window.location.href;
    let urlPath = new URL(url);
    let param = new URLSearchParams(urlPath.search);
    let cid = param.get('cid');

    let utm_source = param.get('utm_source');
    let utm_campaign = param.get('utm_campaign');
    let utm_medium = param.get('utm_medium');

    if (cid)
    {
        utm_source = utm_source ? ', ' + utm_source : '';
        utm_campaign = utm_campaign ? ', ' + utm_campaign : '';
        utm_medium = utm_medium ? ', ' + utm_medium : '';
        objOSGlobal.requestBodyValues.CampaignName = cid + utm_source + utm_campaign + utm_medium;
    } else
    {
        objOSGlobal.requestBodyValues.CampaignName = 'N/A';
    }
}

function setIstestCondtion() {
    let IsTest:any;
    var host = window.location.hostname.toLowerCase();
    if(host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest')) 
    {
        IsTest = true;
    }else {
        IsTest = false;
    }
    return IsTest;
}

function scrollToThankyouSection(){
    let regexp:any = /android|iphone|kindle|ipad/i;
    let Devicedetails:any = navigator.userAgent;
    let isMobileDevice = regexp.test(Devicedetails);
    if(isMobileDevice) {
    $('html, body').animate({
    scrollTop: $(".pagination-section").offset().top
    }, 500);   
    }
}

$('input[name="blogCustomerType"]').click(function(){
    debugger;
       var $radio = $(this);

       // if this was previously checked
       if ($radio.data('waschecked') == true)
       {
           $radio.prop('checked', false);
           $radio.data('waschecked', false);
       }
       else
           $radio.data('waschecked', true);

       // remove was checked from other radios
       $radio.siblings('input[name="blogCustomerType"]').data('waschecked', false);
   });


function addMRAFormItems(){
    var  extraFormInputs = $( `
    <div class="col-sm-12">
	<div class="form-group">
	 <div class="custom-checkbox"> 
	 <input id="appliance-waranty" type="checkbox"> 
	 <label class="body-text-sm"  id ="appliance-waranty-label" for="appliance-waranty">
     <span class = "checkbox-txt"> My appliance is covered by a manufacturer's warranty or service contract.</spa> </label>
	 </div> 
	 </div>
	 </div>

    <div class="col-sm-12 d-none">
    <div class="form-group">
    <label class="body-text-sm" for="inputZip">Would you like to receive the latest offers , updates and helpful tips?</label>
    <div class="form-check pl-0">
    <label class="custom-radio-secondary small-radio" for="blogReceiveYes">
    <input type="radio" name="blogReceiveUpdate" id="blogReceiveYes" checked="">
    <span class="radio-circle" aria-hidden="true"></span>
    <span class="radio-txt">Yes</span>
    </label>
    <label class="custom-radio-secondary small-radio" for="blogReceiveNo">
    <input type="radio" name="blogReceiveUpdate" id="blogReceiveNo">
    <span class="radio-circle" aria-hidden="true"></span>
    <span class="radio-txt">No</span>
    </label></div></div></div>` );
    $("#blogContactForm #blogReceiveOffers").parent().parent().hide();  
    $("#blogContactForm #blogInputComments").parent().parent().after(extraFormInputs);

    $("label[for='blogInputAppartmentTxt']").text("Apartment, suite, unit, etc. (optional)");    
    
}

function setThanksMessage(){
    var thanksMessage = ` Thank you for contacting Mr. Appliance `;
   // $(".blog-list-section .contact-form .thank-you p").hide();
    $(".blog-list-section .contact-form .thank-you h5").text(thanksMessage);
    $('#blogContactForm .form-row').addClass('d-none');
    $('#blogContactForm .thank-you').removeClass('d-none');
    $('.blog-list-section .contact-form .thank-you').css('display','block');
    scrollToThankyouSection();
    stopLoader();
}

function setInvalidZipCodeError(zipCode:any){
    var errorMessage = `We're sorry. We don't currently provide service to ${zipCode}`;
    $(".blog-list-section .contact-form .thank-you p").hide();
    $(".blog-list-section .contact-form .thank-you h5").text(errorMessage);
    $('#blogContactForm .form-row').addClass('d-none');
    $('#blogContactForm .thank-you').removeClass('d-none');
    $('.blog-list-section .contact-form .thank-you').css('display','block');
    scrollToThankyouSection();
    stopLoader();
}