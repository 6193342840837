import {$} from '../../third-party-imports';
import { GetReferenceDetails, apiCall, checkZipLocalSite, erasePreFilledDataOnClose, mrApplianceBrandList, mraOnlineFormSubmit, mraOnlineScheduleFlowSubmitForm, stopLoader } from "../../util";
import { servieModal } from '../../util/get-brands-json';
import { getDefaultHeaderLogo } from '../../util/get-default-header-logo';
import { MraProgressBar } from '../../util/mra-progress-bar';
import { Get_Diagnostic_Fee_API_MRA } from '../getDiagnosticFeeApiMra';

export function mraOsflowSchenario(){
        $(".js-next-btn").click(function(this:any) {
            document.getElementById("js-schedulerDialog").scroll(0, 0);
               let nextId = $(this).parents(".tab-pane").next().attr("id");
               if (nextId == "step2") {
                    apiCall.error = false;
                    $('[href="#' + nextId + '"]').tab("show");
               }
               else if (nextId == "stepfour") {
                   $('.scheduler-modal .modal-content').addClass('banner-wizard');
                   return;
               }
               else if(nextId =="step3"){
                if (!$("#warrantyDetails").valid()) {
                    return;
                }
                    let getZipCode = localStorage.getItem('zipcode');
                    let serviceNeeds = $('.mra-service-info-screen textarea[name="inputService"]').val();
                    let brandAppliance = $('select[name="howDidHereAbout"]').val();
                    $('input[name="inputZipCode"]').val(getZipCode);
                    $('.tab-pane.fieldset').removeClass('confirmation-screen');
                    let contactHereAbout = $('.mra-contact-information-screen .referenceDetails').attr('name');
                    sessionStorage.setItem('contactHereAbout',contactHereAbout);
                    if(brandAppliance !="" || brandAppliance !=null){
                        GetReferenceDetails();
                    }
                    // if ($("#warranty").is(":checked")) || if(window.objOSGlobal.requestBodyValues.IsLeadOnly){  > .mra-service-details-screen else .mra-contact-information-screen
                    if ($("#warranty").is(":checked")) {
                        window.objOSGlobal.requestBodyValues.Warranty = true;
                        window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.requestBodyValues.Note.concat("\nWarranty:TRUE\n");
                        localStorage.setItem("estIsLeadOnly",'true')
                        $('a[href="#step3"]').parent().addClass("d-none");
                        $('a[href="#step5"]').parent().removeClass("d-none")
                        $('[href="#step4"]').tab("show");
                        $('.mra-service-details-screen').addClass('confirmation-screen');
                        $('.mra-contact-information-screen .js-next-btn').html("Next "+$('.js-next-btn img')[0].outerHTML);
                        $('.mra-contact-information-screen .referenceDetails').attr('name','');
                        $('.mra-contact-information-screen .referenceDetails').parent().addClass('d-none');
                        $('.mra-service-details-screen textarea[name="inputService"]').val(serviceNeeds);
                       
                    }else{
                        if(window.objOSGlobal.requestBodyValues.IsLeadOnly){
                            window.objOSGlobal.requestBodyValues.Warranty = true;
                            window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.requestBodyValues.Note.concat("\nWarranty:FALSE\n");
                            $('a[href="#step3"]').parent().addClass("d-none");
                            $('a[href="#step5"]').parent().removeClass("d-none")
                            $('[href="#step4"]').tab("show");
                            $('.mra-service-details-screen').addClass('confirmation-screen');
                            $('.mra-contact-information-screen .js-next-btn').html("Next "+$('.js-next-btn img')[0].outerHTML);
                            $('.mra-contact-information-screen .referenceDetails').attr('name','');
                            $('.mra-contact-information-screen .referenceDetails').parent().addClass('d-none');
                            $('.mra-service-details-screen textarea[name="inputService"]').val(serviceNeeds);
                           
                        }else{
                            if(!apiCall.error){
                                $('a[href="#step3"]').parent().removeClass("d-none");
                                $('a[href="#step5"]').parent().addClass("d-none");
                                $('[href="#' + nextId + '"]').tab("show");
                                $('.mra-contact-information-screen .referenceDetails').attr('name',contactHereAbout);
                                $('.mra-contact-information-screen .referenceDetails').parent().removeClass('d-none');
                                $('.mra-contact-information-screen').addClass('confirmation-screen');
                            }
                        }
                    }
                    
                    $('.confirmation-screen .js-next-btn').html("Submit "+$('.js-next-btn img')[0].outerHTML);
                    Get_Diagnostic_Fee_API_MRA();
                }
                else if(nextId=='step4'){
                    let $validate:any = validate_the_calendar_step( $(this) ); 
                    if( ! $validate ) {
                        return;
                    }
                    activaTab(nextId);
                }
                else if(nextId =="step5"){
                    if (!$("#contactusformMra").valid()) {
                        $('.contact-information-screen').addClass('active');
                        return;
                    }
                    var changeZip = sessionStorage.getItem('changeZip');
                    if(changeZip){
                    checkZipLocalSite(nextId);
                    return;
                    }else{
                        if(apiCall.error){    
                            window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
                        }
                        mraOnlineFormSubmit();
                        if($('.mra-contact-information-screen').hasClass('confirmation-screen')){
                            mraOnlineScheduleFlowSubmitForm();
                        }else{
                            $('[href="#' + nextId + '"]').tab('show');
                            let hereAboutUs = $('.mra-service-details-screen .referenceDetails').attr('name');
                            if(hereAboutUs !='howDidHereAbout'){
                                $(".service-details-screen .next").removeClass("disble-btn").prop("disabled", false);
                            }
                            Get_Diagnostic_Fee_API_MRA();
                        }
                    }
                }
               else{
                   activaTab(nextId)
               }
               if (nextId == "step1" || nextId == "step2") {
                   $('.scheduler-modal .modal-content').addClass('banner-wizard');
               } else {
                   $('.scheduler-modal .modal-content').removeClass('banner-wizard');
               }
               return false;
           });
           $(".js-previous-btn").click(function(this:any) {
            let brandJsonItems = window.objOSGlobal.requestBodyValues.brandJsonGlobalObj;
            let checkCroleadEnable = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match || false ;
            getDefaultHeaderLogo();
               let prevId = $(this).parents(".tab-pane").prev().attr("id");
               if (prevId == "step1") {
                   $('.scheduler-modal .modal-content').addClass('banner-wizard');
                   if(sessionStorage.getItem('serCatBack')){
                         $('.tab-pane.fieldset').removeClass('active');
                         $('.zip-entry-screen').addClass('active');
                         sessionStorage.removeItem('serCatBack')
                         sessionStorage.setItem('servicePageClick','true');
                    }else if(sessionStorage.getItem('serCatBtnBack')){
                          $('.tab-pane.fieldset').removeClass('active');
                          $('.service-list-screen').addClass('active');
                     }else{
                         $('.schedulerModalLabel').addClass('d-none');
                         $('.schedulerModalLabel').empty();
                         $('.tab-pane.fieldset').removeClass('active');
                         if(servieModal.enable){
                            if(checkCroleadEnable){
                                $('.progressbar').hide();
                                if(!window.objOSGlobal.requestBodyValues.fsaLead){
                                    $('#step-location').addClass('active');
                                    $('.modal-header-one').removeClass('d-none');
                                    $('.modal-header-two').addClass('d-none');
                                }else{
                                    $('.modal-header-one').addClass('d-none');
                                    $('.modal-header-two').removeClass('d-none');
                                    $('.zip-entry-screen').addClass('active');
                                }
                            }else{
                                $('.zip-entry-screen').addClass('active');
                                $('.modal-header-two').removeClass('d-none');
                                $('.modal-header-one').addClass('d-none');
                            }
                         }else{
                            $('.service-search-screen').addClass('active');
                        }
                      }
                  return;
               }
               else if(prevId =="step3"){
                   if ($("#warranty").is(":checked")) {
                       $('[href="#step2"]').tab("show");
                   }else{
                       if(window.objOSGlobal.requestBodyValues.IsLeadOnly || apiCall.error){
                           apiCall.error = false;
                           $('[href="#step2"]').tab("show");
                       }else{
                           $('[href="#' + prevId + '"]').tab("show")
                       };
                   } 
               }else{
                   activaTab(prevId);
               }
               return false;
           });
           $('a[data-toggle="tab"]').on("shown.bs.tab", function(e:any) {
               //update progress
               let isLead = window.objOSGlobal.requestBodyValues.IsLeadOnly;
               let checker:any = false;
               if(isLead){    
                  checker=true;    
               }else{
                   if($("#warranty").is(":checked")){
                        checker = true;
                   }else{
                        checker = false;
                   }
               }
               if(apiCall.error){
                 checker = false;
               }
               let step = $(e.target).data("step");
               let percent:any = ((parseInt(step) / 3) * 100).toFixed(2);
               switch (step) {
                   case 1:
                       percent = 5.2;
                       $(".progress-bar").css({ width: percent + "%" });
                       break;
                   case 2:
                       percent = 5.2;
                       $(".progress-bar").css({ width: percent + "%" });
                       break;
                   case 3:
                       percent = 53;
                       $(".progress-bar").css({ width: percent + "%" });
                       break;
                   case 4:
                        percent = checker ? 53 : 100;
                        $(".progress-bar").css({ width: percent + "%" });
                        break;    
                   case 5:
                        percent = 100;
                        $(".progress-bar").css({ width: percent + "%" });
                        break;     
                   case 7:
                       percent = 100;
                       $(".progress-bar").css({ width: percent + "%" });
                       $(".progress").addClass("d-none");
                       $(".progressbar").addClass("d-none");
                       $(".modal-header-two").addClass("d-none");
                       $(".modal-header-one").addClass("d-none");
                       break;
               }
           });
           
           $("#warranty").on('change',function(this:any){
                if (this.checked){
                    window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
                    window.objOSGlobal.requestBodyValues.Warranty = true;
                }else{
                    window.objOSGlobal.requestBodyValues.Warranty = false;
                    window.objOSGlobal.requestBodyValues.IsLeadOnly = window.objOSGlobal.requestBodyValues.calendarLead;
                }
           });          
        $("#warrantytrackbtn").click(function(){
            mraWarrantyTracker();
        });
}
//US-317889(MRA: Enhanced GA4 Event Tracking - Warranty Checkbox(adding a custom event))
function mraWarrantyTracker()
{
    let warrantyCheckboxValue = {
        event: 'warrantyCheckboxEventTracking',
        id: "warranty",
        value: false
    };
    if ($("#warranty").is(":checked"))
    {
        warrantyCheckboxValue.value = true;
    }
    dataLayer.push(warrantyCheckboxValue);
}
function validate_the_calendar_step($button: any){
    let $current_step = $button.parents('.tab-pane');
    let valid = true;
    if ($current_step.find('.calendar-wrapper').length)
    {

        let $active = $current_step.find('.calendar-col .active');

        if ($active.length)
        {
            valid = true;
            $current_step.find('.dt-error').addClass('d-none');
        }
        else
        {
            valid = false;
            $current_step.find('.dt-error').removeClass('d-none');
        }
    }
    return valid;
}


function activaTab(tab:any){
    $('[href="#' + tab + '"]').tab("show");
};


export function mraDefaultCall(nextId:any){
    $('.tab-pane.fieldset').removeClass('active');
    $('[href="#' + nextId + '"]').tab('show');
    $('#' + nextId).addClass('active');
    apiCall.error = false;
    erasePreFilledDataOnClose();
    MraProgressBar();
    mrApplianceBrandList((data: any, status: any) =>
    {
        var mrBrandList: any = [];
        $.each(data, function (index: any, element: any)
        {
            mrBrandList.push('<option id="' + element.makeId + '" value="' + element.makeId + '" data-makeCode="' + element.makeCode + '">' + element.makeName + '</option>');
        });
        $('#warrantyDetails #referenceDetails').empty();
        $('#warrantyDetails #referenceDetails').append('<option value="">- select one</option>')
        $('#warrantyDetails #referenceDetails').append(mrBrandList);
        stopLoader();
    });
    if (window.objOSGlobal.requestBodyValues.IsLeadOnly)
    {
        $('a[href="#step3"]').parent().addClass("d-none");
        $('a[href="#step5"]').parent().removeClass("d-none")
    } else
    {
        $('a[href="#step3"]').parent().removeClass("d-none");
        $('a[href="#step5"]').parent().addClass("d-none");
    }
}