import {$} from "../third-party-imports";
import {getBrandDetailsFromBrandJson, saveContactInfoToSession} from '../util/util';
import { apiConfig } from "../config/apiConfig";
import { croShortForm } from "../util/cro-short-form";
import { startLoader } from "./startLoader";
import { stopLoader } from "./stopLoader";
import { getStateFun } from './getStateFun';
import { requestEstimate } from "./requestEstimate";

$(function() {
    $('#ContactForm .contact-btn').on('click', async function () {
        if ($("#ContactForm").valid()) {
            ContactUsBrands();  
        }
});
if(window.brandData?.enable_contact_us_form_address_feilds || $('#brandName').val().toLowerCase()==='mr-rooter'){
    getStateFun();
}
const showNoLocationFoundMsg = () =>
{
    $('#inputZip-error').text('Invalid ZIP/Postal Code').show();
    $('.contact-error-thankyou .card-title').html(`We're sorry. We don't currently provide service to <span class="noservice-zipcode">${window.objOSGlobal.requestBodyValues.ZipCode}</span>`);
    $('.contact-error-thankyou').removeClass('d-none');
    $('.contact-row').addClass('d-none');
    stopLoader();
}

$(document).on("click", '.contact-error-thankyou .js-next-btn', function(){
    $('.contact-error-thankyou').addClass('d-none');
    $('.contact-row').removeClass('d-none');
});

function setIstestCondtion() {
    let IsTest;
    var host = window.location.hostname.toLowerCase();
    if(host.includes('nblysbx') || host.includes('nblydev') || host.includes('nblytest')) 
    {
        IsTest = true;
    }else {
        IsTest = false;
    }
    return IsTest;
} 

    async function ContactUsBrands() {
        startLoader();
        let domainName = window.location.origin.toLowerCase();
        let domainType = window.DEFINE_VALUE.Domain_Type;
        const objOSGlobal = window.objOSGlobal;
        var host = window.location.hostname.toLowerCase();
        objOSGlobal.requestBodyValues.IsTest = setIstestCondtion();
        let zipcode = $('#ContactForm #inputZip').val();
        var isWebSite = domainName.indexOf("-ca-") === -1 && domainName.indexOf(".ca") === -1 && domainName.indexOf("-ca.") == -1 ? "us" : "ca";
        if (isWebSite == 'ca'){
            zipcode = zipcode.indexOf(" ") == -1 ? zipcode.replace(/^(.{3})(.*)$/, "$1 $2") : zipcode;
        }
       if (host.indexOf("www-nei") == -1 || host.indexOf("nblyprod") != -1){
            objOSGlobal.requestBodyValues.IsTest = false;
        }
        saveContactInfoToSession();
        const result = await getBrandDetailsFromBrandJson();
        if (result.length > 0){
            var items = $.grep(result, function (e: any) { return e.code === $('#conceptCode').val() });
            objOSGlobal.requestBodyValues.VendorId = items[0].vendorId ? items[0].vendorId : ""; 
            objOSGlobal.requestBodyValues.VendorName = items[0].vendorName ? items[0].vendorName : "";        
            objOSGlobal.requestBodyValues.ConceptId = $('#conceptId').val();
            objOSGlobal.requestBodyValues.WebLocationId = $('#weblocationId').val();
            objOSGlobal.requestBodyValues.CustomerType = $('#ContactForm input[name=customerType]:checked').parent().find('.radio-txt').html();
            objOSGlobal.requestBodyValues.FirstName = $('#ContactForm #inputFname').val();
            objOSGlobal.requestBodyValues.LastName = $('#ContactForm #inputLname').val();
            objOSGlobal.requestBodyValues.ZipCode = zipcode;
            objOSGlobal.requestBodyValues.Phone = $('#ContactForm #inputPhone').val();
            objOSGlobal.requestBodyValues.Email = $('#ContactForm #inputEmail').val();
            objOSGlobal.requestBodyValues.Comments = $('#ContactForm #inputComments').val();
            objOSGlobal.requestBodyValues.isLocalized = false;
            objOSGlobal.requestBodyValues.SignUpUpdates = $('#ContactForm input[type="checkbox"]').is(":checked");
            var conceptID = window.objOSGlobal.requestBodyValues.ConceptId;
            window.objOSGlobal.requestBodyValues.PostalCode = zipcode;
            objOSGlobal.requestBodyValues.Country = $('#countryCode').val();
            var conceptCode = $('#conceptCode').val();
            var apiUrl = apiConfig.FranchiseLookUpService + window.DEFINE_VALUE.JS_API_KEY + '&Address=' + zipcode + '&IsRoundRobin=true&conceptID=' + conceptID;
            var contactUsUrl = apiConfig.contactUSApi + '?apikey=' + window.DEFINE_VALUE.JS_API_KEY;
            $.ajax({
            type: "GET",
            url: apiUrl,
            contentType: "application/json",
            dataType: 'json',
            success: function (result:any, status:any) {
                // Conditional handling for GUY:- Non-localized: no handling; Localized: Use booking API 
                if(result.length){
                    localStorage.setItem("weblocationId", result[0].franchiseWebLocationId);
                    window.objOSGlobal.paramsUI.doingBusinessAs = result[0].doingBusinessAs;
                }
                if(items[0]?.enable_contact_us_lead_flow){
                    if(result.length==0){
                        showNoLocationFoundMsg();
                    }else{
                        // Use booking API for GGPRO lead gen for GUY
                        croShortForm();
                        objOSGlobal.requestBodyValues.franchiseId = result[0].franchiseId;
                        objOSGlobal.requestBodyValues.franchiseWebLocationId = result[0].franchiseWebLocationId;
                        objOSGlobal.requestBodyValues.isLocalized = true;
                        objOSGlobal.requestBodyValues.LeadOrigin = conceptCode + ' Website';
                        //Formatting the Notes section
                        window.objOSGlobal.requestBodyValues.Note=""
                        if($('#brandName').val().toLowerCase()==='mr-handyman')
                            {
                                objOSGlobal.requestBodyValues.CustomerType?window.objOSGlobal.requestBodyValues.Note+='Are you a new customer?:'+objOSGlobal.requestBodyValues.CustomerType:null;
                                objOSGlobal.requestBodyValues.CustomerType && objOSGlobal.requestBodyValues.Comments?window.objOSGlobal.requestBodyValues.Note+=', ':null;
                                objOSGlobal.requestBodyValues.Comments?window.objOSGlobal.requestBodyValues.Note+='Comments:'+objOSGlobal.requestBodyValues.Comments:null;
                            }
                            else{
                                window.objOSGlobal.requestBodyValues.Note="Contact Us";
                            }
                        
                        var payloadGuy = {
                            "FranchiseId": objOSGlobal.requestBodyValues.franchiseId || 0,
                            "Comments":  objOSGlobal.requestBodyValues.Comments || "",
                            "IsLocalized":  objOSGlobal.requestBodyValues.IsLocalized,
                            "ConceptId":  Number($('#conceptId').val() || 0) , 
                            "ConceptCalledId": Number($('#conceptId').val() || 0) ,
                            "ConceptName":  objOSGlobal.requestBodyValues.ConceptName || "",
                            "ConceptCode":  conceptCode,
                            "CampaignName":" ",
                            "CallType": "  ",
                            "VendorId": objOSGlobal.requestBodyValues.VendorId,
                            "NationalLeadId":  objOSGlobal.requestBodyValues.NationalLeadId || "",
                            "IsTest": objOSGlobal.requestBodyValues.IsTest,
                            "VendorName": objOSGlobal.requestBodyValues.VendorName,
                            "WebLocationId": objOSGlobal.requestBodyValues.franchiseWebLocationId,
                            "ZipCode":   objOSGlobal.requestBodyValues.ZipCode || "",
                            "FirstName": objOSGlobal.requestBodyValues.FirstName || "  ",
                            "LastName":  objOSGlobal.requestBodyValues.LastName || "  ",
                            "Email":  objOSGlobal.requestBodyValues.Email? objOSGlobal.requestBodyValues.Email.trim() : "",
                            "Phone":   objOSGlobal.requestBodyValues.Phone || "",
                            "PhoneType": objOSGlobal.requestBodyValues.PhoneType || "Mobile",
                            "PostalCode":  isWebSite=="ca"? objOSGlobal.requestBodyValues.ZipCode : "",
                            "City": objOSGlobal.requestBodyValues.City ? objOSGlobal.requestBodyValues.City : "",
                            "State": objOSGlobal.requestBodyValues.State ? objOSGlobal.requestBodyValues.State : "",
                            "Country": objOSGlobal.requestBodyValues.Country,
                            "Address": objOSGlobal.requestBodyValues.Address ? objOSGlobal.requestBodyValues.Address : "",
                            "Address2": objOSGlobal.requestBodyValues.Address ? objOSGlobal.requestBodyValues.Address : "",
                            "LeadSource":   "Web",
                            "LicenseNumber":   "",
                            "WebLocationAccountCode":objOSGlobal.requestBodyValues.WebLocationAccountCode || "",
                            "LeadOrigin":  objOSGlobal.requestBodyValues.LeadOrigin,
                            "ReferringURL": document.referrer,
                            "CurrentPage":  window.location.hostname.toLowerCase(),
                            "EmailOptOut":  false,
                            "LeadSourceId":   0,
                            "SecondaryLeadSourceId":   0,
                            "ServiceTypeProblem":   "",
                            "OverrideJobPromisedDate":   "",
                            "ServiceSkuId":   0,
                            "ServiceSkuIds":  "",
                            "IsEstimate": objOSGlobal.requestBodyValues.IsEstimate || false,
                            "IsLeadOnly": true,
                            "IsEmergencyCall":   false,
                            "IsFranchiseInPOS":  true,
                            "CustomerType":   objOSGlobal.requestBodyValues.CustomerType || "",
                            "NeighborlyAccountId":   objOSGlobal.requestBodyValues.NeighborlyAccountId || 0,
                            "NeighborlyLeadSourceId":   objOSGlobal.requestBodyValues.NeighborlyLeadSourceId || "",
                            "ReferralTypeId":   objOSGlobal.requestBodyValues.ReferralTypeId || null,
                            "ReferralType":   objOSGlobal.requestBodyValues.ReferralType || null ,
                            "Note": window.objOSGlobal.requestBodyValues.Note,  
                            "LeadAdditionalNotes": {
                                "LeadAdditionalNoteLabel": "Contact Us Form",
                                "LeadAdditionalNoteText": ""
                            }
                        };
                        var formDataGuy = JSON.stringify(payloadGuy);
                        $.ajax({
                            url: window.DEFINE_VALUE.bookingEndpoint + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY,
                            type: "POST",
                            contentType: "application/json",
                            dataType: "json",
                            data: formDataGuy,
                            success: function (res:any, status:any) {
                                requestEstimate('contact_us','long-form','success','');
                                successHandlerGuy(res,formDataGuy,domainType,domainName,status);
                            },
                            error: function (error:any,status:any){ 
                                requestEstimate('contact_us','long-form','fail','');
                                console.log(error);
                            }
                        });
    
                    }
                }
                else{
                // Handling for other brands:- Non-localized and Localized: payload changes 
                    if (result.length === 0) {
                        objOSGlobal.requestBodyValues.franchiseWebLocationId = "";
                        objOSGlobal.requestBodyValues.isLocalized = false;
                    }else {
                        objOSGlobal.requestBodyValues.franchiseId = result[0].franchiseId;
                        objOSGlobal.requestBodyValues.franchiseWebLocationId = result[0].franchiseWebLocationId;
                        objOSGlobal.requestBodyValues.isLocalized = true;            
                    }
                    const payload = {
                        "FranchiseWebLocationId": objOSGlobal.requestBodyValues.franchiseWebLocationId,
                        "ConceptId": objOSGlobal.requestBodyValues.ConceptId,
                        "IsLocalized": objOSGlobal.requestBodyValues.isLocalized,
                        "FirstName": objOSGlobal.requestBodyValues.FirstName ? objOSGlobal.requestBodyValues.FirstName : "",
                        "LastName": objOSGlobal.requestBodyValues.LastName ? objOSGlobal.requestBodyValues.LastName : "",
                        "ZipCode": objOSGlobal.requestBodyValues.ZipCode ? objOSGlobal.requestBodyValues.ZipCode : "",
                        "Email": objOSGlobal.requestBodyValues.Email ? objOSGlobal.requestBodyValues.Email.trim() : "",
                        "Phone": objOSGlobal.requestBodyValues.Phone ? objOSGlobal.requestBodyValues.Phone : "",
                        "IsNewCustomer": objOSGlobal.requestBodyValues.CustomerType,
                        "Comments": objOSGlobal.requestBodyValues.Comments ? objOSGlobal.requestBodyValues.Comments : "",
                        "SignUpForUpdates": objOSGlobal.requestBodyValues.SignUpUpdates,
                        "IsTest": objOSGlobal.requestBodyValues.IsTest,
                        "VendorId": objOSGlobal.requestBodyValues.VendorId,
                        "Note":"Contact Us"
                    };
                    if(window.brandData?.enable_contact_us_form_address_feilds || $('#brandName').val().toLowerCase()==='mr-rooter'){
                        objOSGlobal.requestBodyValues.Address = $('#ContactForm #inputAddress').val() + " " + $('#ContactForm #inputAddress2').val();
                        objOSGlobal.requestBodyValues.City = $('#ContactForm #inputCity').val();
                        objOSGlobal.requestBodyValues.State = $('#ContactForm #inputState').val();
                        Object.assign(payload, {
                            Address: objOSGlobal.requestBodyValues.Address,
                            City: objOSGlobal.requestBodyValues.City,
                            State: objOSGlobal.requestBodyValues.State
                        });
                    }
                    let formdata = JSON.stringify(payload);
                    $.ajax({
                        url: contactUsUrl,
                        type: "POST",
                        contentType: "application/json",
                        data: formdata,
                        success: function (res:any, status:any) {
                            
                            requestEstimate('contact_us','long-form','success','');
                            successHandlerOpus(status,formdata,domainType,domainName,res);
                        },
                        error: function (xhr:any, status:any, error:any) {
                            requestEstimate('contact_us','long-form','fail','');
                            console.log(error);
                        }
                    });
                }   
            },
            error: function (data:any, status:any) {
                if (domainType != "digital") {
                    var afterCom = window.location.href.split('/')[3];
                    var isWebSite = afterCom.split('/')[1].replace(/[_\W]+/g, "") === "ca" ? "/ca" : "";
                    window.location.replace(window.location.origin + isWebSite + "/error");
                } else {
                    var afterComSuccess = window.location.href.split('/')[3];
                    var websiteurl = $('#weblocationId').length ? domainName + "/" + afterComSuccess : domainName;
                    window.location.replace(websiteurl + "/" + "error");
                }
            }
            });
        }
    }

function successHandlerGuy(res,formdata,domainType,domainName,status){
    if(status == 'success'){
        let apiData = '{ "Result":'+ JSON.stringify(res) +', "Form data":'+ formdata +', "Response message":"SUCCESS" }';
        sessionStorage.setItem('NEI-API data',apiData);
        var afterComSuccess = window.location.href.split('/')[3];
        let websiteurl;
        if (domainType != "digital") {
            var isWebSite = afterComSuccess.split('/')[1].replace(/[_\W]+/g, "") === "ca" ? "/ca" : "";
            if ($('#weblocationId').length) {
                if (isWebSite) {
                    websiteurl = afterComSuccess.split('/')[1] + "/" + afterComSuccess.split('/')[2] + "/" + afterComSuccess.split('/')[3] + "/" + afterComSuccess.split('/')[4];
                } else {
                    websiteurl = afterComSuccess.split('/')[1] + "/" + afterComSuccess.split('/')[2] + "/" + afterComSuccess.split('/')[3];
                }
            } else {
                if (isWebSite) {
                    websiteurl = afterComSuccess.split('/')[1] + "/" + afterComSuccess.split('/')[2];
                } else {
                    websiteurl = afterComSuccess.split('/')[1];
                }
            }
                window.location.replace(window.location.origin + "/" + websiteurl + "/" + "thank-you");
        } else {
            websiteurl = $('#weblocationId').length ? domainName + "/" + afterComSuccess : domainName;
            window.location.replace(websiteurl + "/" + "thank-you");    
        }
        
    }
}

function successHandlerOpus(status,formdata,domainType,domainName,res){

    if (res == 'success') {
        let apiData = '{ "Result":'+ JSON.stringify(res) +', "Form data":'+ formdata +', "Response message":"SUCCESS" }';
        sessionStorage.setItem('NEI-API data',apiData);
        var afterComSuccess = window.location.href.split('/')[3];
        let websiteurl;
        if (domainType != "digital") {
            var isWebSite = afterComSuccess.split('/')[1].replace(/[_\W]+/g, "") === "ca" ? "/ca" : "";
            if ($('#weblocationId').length) {
                if (isWebSite) {
                    websiteurl = afterComSuccess.split('/')[1] + "/" + afterComSuccess.split('/')[2] + "/" + afterComSuccess.split('/')[3] + "/" + afterComSuccess.split('/')[4];
                } else {
                    websiteurl = afterComSuccess.split('/')[1] + "/" + afterComSuccess.split('/')[2] + "/" + afterComSuccess.split('/')[3];
                }
            } else {
                if (isWebSite) {
                    websiteurl = afterComSuccess.split('/')[1] + "/" + afterComSuccess.split('/')[2];
                } else {
                    websiteurl = afterComSuccess.split('/')[1];
                }
            }
            if ($("#conceptCode").val() === "MRR" && window.location.href.includes('/gatineau-francais/')) {
                window.location.replace(window.location.origin + "/" + websiteurl + "/gatineau-francais/" + "thank-you");
            }
            else {
                window.location.replace(window.location.origin + "/" + websiteurl + "/" + "thank-you");
            }
        } else {
            websiteurl = $('#weblocationId').length ? domainName + "/" + afterComSuccess : domainName;
            if ($("#conceptCode").val() === "MRR" && window.location.href.includes('/gatineau-francais/')) {
                window.location.replace(websiteurl + "/gatineau-francais/" + "thank-you");
            } else {
                window.location.replace(websiteurl + "/" + "thank-you");
            }
        } 
                                       
    }
}
});


