import { $ } from "../third-party-imports";
import { onloadAddScript } from "../util";

$(document).ready(function ()
{
    onloadAddScript();
});

window.addEventListener("load", function() {
    initSlider();
});

function initSlider()
{
    let breakpointMobile = 767,
        breakpointDesktop = 768,
        isChanging = false,
        isFiltered = false;

    // Define the init handler before the initialization
    $('.tiles-info-slider').on('init breakpoint', function (event: any, slick: any)
    {
        if (!isChanging)
        {
            // Prevent infinite loop
            isChanging = true;
            // The highest breakpoint is null
            if (slick.activeBreakpoint && slick.activeBreakpoint <= breakpointMobile)
            {
                if (!isFiltered)
                {
                    slick.slickFilter(':not(.desktop-only)');
                    isFiltered = true;
                }
            } else if (slick.activeBreakpoint && slick.activeBreakpoint >= breakpointDesktop)
            {
                if (!isFiltered)
                {
                    slick.slickFilter(':not(.mobile-only)');
                    isFiltered = true;
                }
            } else
            {
                if (isFiltered)
                {
                    slick.slickUnfilter();
                    isFiltered = false;
                }
            }
            isChanging = false;
        }
    }).slick({
        slidesToShow: 3,
        infinite: true,
        arrows: true,
        dots: true,
        adaptiveHeight: true,
        variableWidth: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                    variableWidth: false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerMode: false,
                    variableWidth: false,
                }
            },
            {
                breakpoint: 900,

            },
            { breakpoint: 1200 },
            { breakpoint: 1400 },
            { breakpoint: 1800 },
            { breakpoint: 3800 }
        ]
    });
    $('.carousel-container').slick({
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 3,
        variableWidth: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1
            }
          }
        ]
    });

    $('.slick-prev').on('click', function() {
    $('.carousel-container').slick('slickPrev');
    });
    
    $('.slick-next').on('click', function() {
    $('.carousel-container').slick('slickNext');
    });
      //slider start
}