import { $ } from "../third-party-imports";

export function referAFriendPage()
{
    if($('#refer-a-friend-form').length > 0) {
        $('#opus-refer-a-friend-form-submit-btn').prop("disabled", false);
        $('#opus-refer-a-friend-form-submit-btn').removeClass('disble-btn');        
        $('#opus-refer-a-friend-form-submit-btn').on('click', async function() {
            if (validateForm())
            {   
                loadThankyouSection();
                lookupAndgenerateLead();
            }
        }
        );
             
        $(':input').keyup((e: any) =>
        {
            const inputField = e.currentTarget;
            if (inputField.type === 'tel')
            {
                const formattedInputValue = formatPhoneNumberReferAFriend(inputField.value);
                inputField.value = formattedInputValue;
            }
        });
    }
    
    if (window.location.href.indexOf("referral-thank-you") > -1)
    {
        referFriendData();
    }
}

const validateForm = () =>
{
    const inputs = $('#con-form').find(':input');
    let formFlag = true;
    for (let i = 0; i < inputs.length; ++i)
    {
        const input = inputs[i];
        let flag = true;
        if (input.required === true && input.value.length === 0)
        {
            input.parentElement.classList.add('error');
            flag = false;
            const inputId = $(input).attr('id');
            const errorMsgId = inputId + '-error';
            insertErrorMsg(inputId, `Please Enter ${$($(input).prev()[0]).text()}`);
            $(`#${errorMsgId}`).removeClass('d-none');
        }
        if (input.type === 'tel')
        {
            if (input.value && input.value.length > 0)
            {
                const phoneValidationRes = validatePhoneNumberFormat(input.value);
                if (!phoneValidationRes)
                {
                    input.parentElement.classList.add('error');
                    flag = false;
                    const inputId = $(input).attr('id');
                    const errorMsgId = inputId + '-error';
                    insertErrorMsg(inputId, `Please Enter Correct Phone Number`);
                    $(`#${errorMsgId}`).removeClass('d-none');
                    //return false;
                }
            }
        }
        else if (input.type === 'email')
        {
            if (input.value && input.value.length > 0)
            {
                const validateEmailRes = validateEmail(input.value);
                if (!validateEmailRes)
                {
                    input.parentElement.classList.add('error');
                    flag = false;
                    const inputId = $(input).attr('id');
                    const errorMsgId = inputId + '-error';
                    insertErrorMsg(inputId, `Please Enter Correct Email`);
                    $(`#${errorMsgId}`).removeClass('d-none');
                    //return false;
                }
            }
        }
        if ($(input).attr('id') === 'opus-refer-a-friend-your-zip-code')
        {

            const validateZip = validZipcode(input.value);
            if (!validateZip)
            {
                input.parentElement.classList.add('error')
                flag = false;
                const inputId = $(input).attr('id');
                const errorMsgId = inputId + '-error';
                insertErrorMsg(inputId, `Please Enter Correct Zip Code`);
                $(`#${errorMsgId}`).removeClass('d-none');
                //return false;
            }
        }
        if ($(input).attr('id') === 'opus-refer-a-friend-your-friends-zip-code')
        {
            const validateZip = validZipcode(input.value);
            if (!validateZip)
            {
                input.parentElement.classList.add('error')
                flag = false;
                const inputId = $(input).attr('id');
                const errorMsgId = inputId + '-error';
                insertErrorMsg(inputId, `Please Enter Correct Zip Code`);
                $(`#${errorMsgId}`).removeClass('d-none');
            }
        }
        if ($(input).attr('id') === 'opus-refer-a-friend-your-first-name')
        {
            if (input.value && input.value.length > 0)
            {
                const validateRes = validateName(input.value);
                if (!validateRes)
                {
                    input.parentElement.classList.add('error')
                    flag = false;
                    const inputId = $(input).attr('id');
                    const errorMsgId = inputId + '-error';
                    insertErrorMsg(inputId, `Please Enter Correct First Name`);
                    $(`#${errorMsgId}`).removeClass('d-none');
                }
            }
        }
        if ($(input).attr('id') === 'opus-refer-a-friend-your-last-name')
        {
            if (input.value && input.value.length > 0)
            {
                const validateRes = validateName(input.value);
                if (!validateRes)
                {
                    input.parentElement.classList.add('error')
                    flag = false;
                    const inputId = $(input).attr('id');
                    const errorMsgId = inputId + '-error';
                    insertErrorMsg(inputId, `Please Enter Correct Last Name`);
                    $(`#${errorMsgId}`).removeClass('d-none');
                }
            }
        }
        if ($(input).attr('id') === 'opus-refer-a-friend-your-friends-first-name')
        {
            if (input.value && input.value.length > 0)
            {
                const validateRes = validateName(input.value);
                if (!validateRes)
                {
                    input.parentElement.classList.add('error')
                    flag = false;
                    const inputId = $(input).attr('id');
                    const errorMsgId = inputId + '-error';
                    insertErrorMsg(inputId, `Please Enter Correct Your First Name`);
                    $(`#${errorMsgId}`).removeClass('d-none');
                }
            }
        }
        if ($(input).attr('id') === 'opus-refer-a-friend-your-friends-last-name')
        {
            if (input.value && input.value.length > 0)
            {
                const validateRes = validateName(input.value);
                if (!validateRes)
                {
                    input.parentElement.classList.add('error')
                    flag = false;
                    const inputId = $(input).attr('id');
                    const errorMsgId = inputId + '-error';
                    insertErrorMsg(inputId, `Please Enter Correct Last Name`);
                    $(`#${errorMsgId}`).removeClass('d-none');
                }
            }
        }
        if ($(input).attr('id') === 'opus-refer-a-friend-reason-for-referral')
        {
            if (input.value && input.value.length > 0)
            {
                const validateRes = input.value.length <= 250;
                if (!validateRes)
                {
                    input.parentElement.classList.add('error')
                    flag = false;
                    const inputId = $(input).attr('id');
                    const errorMsgId = inputId + '-error';
                    insertErrorMsg(inputId, `Please Enter 250 Characters or Less`);
                    $(`#${errorMsgId}`).removeClass('d-none');
                }
            }
        }
        if (flag)
        {
            input.parentElement.classList.remove('error');
            $(input).next().addClass('d-none');
        }
        formFlag = formFlag && flag;
    }
    return formFlag;
}

function formatPhoneNumberReferAFriend(value: any)
{
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7)
    {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
    )}-${phoneNumber.slice(6, 10)}`;
}
function validatePhoneNumberFormat(value:any)
{
    var re = /\(\d{3}\) \d{3}-\d{4}/;
    var res = re.test(value);
    return res;
}
function validateEmail(value: any)
{
    const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return filter.test(value);
}
function validZipcode(value: any)
{
    var zipRegex = new RegExp(/^([0-9]{5}|[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9])$/);
    return zipRegex.test(value);
}
const loadThankyouSection = () =>
{
    const formDataValue = {
        "FirstName": $('#opus-refer-a-friend-your-first-name').val(),
        "LastName": $('#opus-refer-a-friend-your-last-name').val(),
        "Email": $('#opus-refer-a-friend-your-email-address').val(),
        "Phone": $('#opus-refer-a-friend-your-phone-number').val(),
        "Address": $('#opus-refer-a-friend-your-address').val(),
        "PostalCode": $('#opus-refer-a-friend-your-zip-code').val(),
        "comment":$('#opus-refer-a-friend-comments').val(),
        "referralFirstName": $('#opus-refer-a-friend-your-friends-first-name').val(),
        "referralLastName": $('#opus-refer-a-friend-your-friends-last-name').val(),
        "referralAddress": $('#opus-refer-a-friend-your-friends-address').val(),
        "referralZipCode": $('#opus-refer-a-friend-your-friends-zip-code').val(),
        "referralPhone": $('#opus-refer-a-friend-your-friends-phone-number').val(),
        "referralEmail": $('#opus-refer-a-friend-your-friends-email-address').val(),
        "referralReason": $('#opus-refer-a-friend-reason-for-referral').val(),
        "referralCity": $('#opus-refer-a-friend-your-friends-city').val(),
        "referralState": $('#opus-refer-a-friend-your-friends-state').val(),
        "referralAddress2":$('#opus-refer-a-friend-your-friends-address2').val()
    }
    localStorage.setItem('refer-refriend', JSON.stringify(formDataValue));
}
const getFormData = () =>
{
    let conceptCalledId = $('#conceptId').val();
    let leadOrigin  =  $("#conceptCode").val();
    const formData = {
        "IsLocalized": true,
        "ConceptCalledId": conceptCalledId,
        "VendorId": 72,
        "WebLocationId": 8715,
        "Note": "Referral",
        "FirstName": $('#opus-refer-a-friend-your-first-name').val(),
        "LastName": $('#opus-refer-a-friend-your-last-name').val(),
        "Email": $('#opus-refer-a-friend-your-email-address').val(),
        "Phone": $('#opus-refer-a-friend-your-phone-number').val(),
        "PhoneType": "Mobile",
        "PostalCode": $('#opus-refer-a-friend-your-zip-code').val(),
        "Comment":$('#opus-refer-a-friend-comments').val(),
        "City": "Waco",
        "State": "TX",
        "Country": $("#countryCode").val() ? $("#countryCode").val().toUpperCase() : "US",
        "Address":   "Streat 1234",
        "Address2": "",
        "LeadOrigin": `${leadOrigin} Website`,
        "CampaignName": "N/A",
        "CallType": "In Bound",
        "EmailOptOut": true,
        "TagName": "",
        "IsLeadOnly": true,
        "Warranty":true,
        "referralFirstName": $('#opus-refer-a-friend-your-friends-first-name').val(),
        "referralLastName": $('#opus-refer-a-friend-your-friends-last-name').val(),
        "referralZipCode": $('#opus-refer-a-friend-your-friends-zip-code').val(),
        "referralPhone": $('#opus-refer-a-friend-your-friends-phone-number').val(),
        "referralEmail": $('#opus-refer-a-friend-your-friends-email-address').val(),
        "referralReason": $('#opus-refer-a-friend-reason-for-referral').val(),
    }
    return formData;
}

const lookupAndgenerateLead = () =>
{
    const addressForLookup = $('#opus-refer-a-friend-your-friends-zip-code').val();
    const lookupDomain = window.DEFINE_VALUE['JS_UnifiedSyncPlatform_URL'];
    const apikey = window.DEFINE_VALUE['JS_API_KEY'];
    const conceptID = $('#conceptId').val();
    $.ajax({
        async:false,
        url: `${lookupDomain}/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress?&Address=${addressForLookup}&IsRoundRobin=false&conceptID=${conceptID}&RequireAddressOnPartialPostalCode=true&apikey=${apikey}`,
        success: (response: any) =>
        {
            if (response.length > 0)
            {
                let webLocationId = response[0].franchiseWebLocationId;
                generateLead(webLocationId);
            }
            else if (response.length === 0)
            {
                showNoLocationFoundMsg();
            }
        },
        error: (err: any) =>
        {
            console.log('lookup call failed: ', err);
        }
    });
}
const generateLead = (WebLocationId: any) =>
{
    const body = getFormData();
    body.WebLocationId = WebLocationId;

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const domain = window.DEFINE_VALUE['bookingEndpoint'];
    const apikey = window.DEFINE_VALUE['apikey'];
    const url = `${domain}?apikey=${apikey}`;
    let payload = {"IsLocalized": true,
                    "ConceptCalledId":body.ConceptCalledId,
                    "VendorId": body.VendorId,
                    "WebLocationId":WebLocationId,
                    "Note": body.Note,
                    "FirstName": body.FirstName,
                    "LastName": body.LastName,
                    "Email": body.Email,
                    "Phone": body.Phone,
                    "PhoneType": "Mobile",
                    "PostalCode": body.PostalCode,
                    "City": "Waco",
                    "State": "TX",
                    "Country": "US",
                    "Address": body.Address,
                    "Address2": "",
                    "LeadOrigin": body.LeadOrigin,
                    "CampaignName": "N/A",
                    "CallType": "In Bound",
                    "EmailOptOut": false,
                    "TagName": "",
                    "IsLeadOnly": true,
                    "referralFirstName": body.referralFirstName,
                    "referralLastName": body.referralLastName,
                    "referralZipCode": body.referralZipCode,
                    "referralPhone": body.referralPhone,
                    "referralEmail": body.referralEmail,
                    "referralReason": body.referralReason,
                    "CurrentPage": null,
                    "CustomerType": "",
                    "NeighborlyLeadSourceId": 11,
                    "leadSource":"Refer a Friend",
                    "leadOrigin":"Refer a Friend",
                    "currentPage":window.location.ref,
                    "Warranty":true,
                    "ServiceSkuId":7989,
                    "ServiceSkuIds":[7989],
                     "MakeId":133,
                    "serviceTypeID":0
                }
    var formdata = JSON.stringify(payload);   
    fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    })
        .then(response => response.text())
        .then(result => {
            redirectToThankyouPage();                     
            let apiData = '{ "Result": '+ JSON.stringify(result) +', "Form data": '+ formdata +', "Response message":"SUCCESS" }';
            sessionStorage.setItem('NEI-API data',apiData);
        })
        .catch(error => console.log('error', error));
}
const insertErrorMsg = (inputId:any, msg:any) =>
{
    $(`#${inputId}-error`).html(`<img alt="error-icon" src="/us/en-us/brand/_assets/images/error-icon.svg" /> ${msg}`);
}
const validateName = (name:any) =>
{
    return /^[a-zA-Z][a-zA-Z0-9.,$;]+$/.test(name);
}
function referFriendData()
{
    let referObj = JSON.parse(localStorage.getItem('refer-refriend'));
    $('#opus-refer-a-friend-your-first-name-thank-you-sec').text(referObj.FirstName);
    $('#opus-refer-a-friend-your-last-name-thank-you-sec').text(referObj.LastName);
    $('#opus-refer-a-friend-your-phone-number-thank-you-sec').text(referObj.Phone);
    $('#opus-refer-a-friend-your-address-thank-you-sec').text(referObj.Address);
    $('#opus-refer-a-friend-your-zip-code-thank-you-sec').text(referObj.PostalCode);
    $('#opus-refer-a-friend-your-email-address-thank-you-sec').text(referObj.Email);
    $('#opus-refer-a-friend-your-friends-first-name-thank-you-sec').text(referObj.referralFirstName);
    $('#opus-refer-a-friend-your-friends-last-name-thank-you-sec').text(referObj.referralLastName);
    $('#opus-refer-a-friend-your-friends-phone-number-thank-you-sec').text(referObj.referralPhone);
    $('#opus-refer-a-friend-your-friends-address-thank-you-sec').text(referObj.referralAddress);
    $('#opus-refer-a-friend-your-friends-zip-code-thank-you-sec').text(referObj.referralZipCode);
    $('#opus-refer-a-friend-your-friends-email-address-thank-you-sec').text(referObj.referralEmail);
    $('#opus-refer-a-friend-reason-for-referral-thank-you-sec').text(referObj.referralReason);
    $('#opus-refer-a-friend-comments-thank-you-sec').text(referObj.comment);
    $('#opus-refer-a-friend-your-friends-city-thank-you-sec').text(referObj.referralCity);
    $('#opus-refer-a-friend-your-friends-state-thank-you-sec').text(referObj.referralState);
    $('#opus-refer-a-friend-your-friends-address2-thank-you-sec').text(referObj.referralAddress2);
}
const redirectToThankyouPage = () =>
{
    let newHref = window.location.origin;
    let currentPath = window.location.pathname;
    let endPoint = 'referral-thank-you' 
    window.location.href = newHref + currentPath.replace("referral",endPoint);
}
const showNoLocationFoundMsg = () =>
{
    $('#refer-a-friend-invalid-zip-code').removeClass('d-none');
    $('#refer-a-friend-form').addClass('d-none');
    $('#refer-a-friend-invalid-zip-zip').text(' ' + $('#opus-refer-a-friend-your-friends-zip-code').val());
}

$('#refer-a-friend-form #opus-refer-a-friend-your-friends-zip-code, #refer-a-friend-form #opus-refer-a-friend-your-zip-code').keypress(function(this: any, e: any){
    if (this.value === "0000" && e.which == 48){
    return false;
    }
});
