
import { $ } from '../third-party-imports';
import { youtubeInit } from '../util/youtubeInit';


$(window).on("load", function() {

    $(window).scroll(function() {
        var allCssList = document.querySelectorAll('[rel="stylesheet"]');
        for (var i = 0; i < allCssList.length; i++)
        {
            allCssList[i].removeAttribute('disabled');
        }
    });
    $('*').hasClass('youtube') && youtubeInit();
});

$(function() {
    $(document).on("click", ".dropdown-menu", function(e) {
        e.stopPropagation();
    });
    $(".close-icn").on("click", function() {
        $(".notify-sec").hide();
        $("body").addClass("notify-remove");
    });

    // close if press ESC button
    $(document).on("keydown", function(event) {
        if (event.keyCode === 27) {
            $(".navbar-collapse").removeClass("show");
            $("body").removeClass("overlay-active");
        }
    });
    // $("[data-trigger]").on("click", function() {
    $(document).on("click", "[data-trigger]", function() {
        let trigger_id = $(this).attr("data-trigger");
        $(trigger_id).toggleClass("show");
        $("body").toggleClass("offcanvas-active");
        $(".js-dropdown").removeClass("active");
        $(".js-dropdown").removeClass("show");
        $(".megamenu").removeClass("show");
        $(".js-dd-inner").removeClass("active");
        $('.dropdown .dropdown-menu .mega-menu-links .js-mega-menu-cont2').removeClass('current');
    });

    $('.close-btn').on('click', function() {
        $('.navbar-collapse').removeClass('show');
        $("body").removeClass("offcanvas-active");
        $('.dropdown .dropdown-menu .mega-menu-links .js-mega-menu-cont2').removeClass('current');
    });
    // Class Target to MAC Screen
    if (navigator.userAgent.indexOf('Mac') > 0) {
        $("body").addClass("mac");
    }
    

    $('.js-mega-menu-cont').hide();

    $('.js-mega-menu-btn').on('click', function() {
        let target_menu = "#" + $(this).data("target");
        $('.js-mega-menu-front').hide();
        $(".js-mega-menu-cont").not(target_menu).hide();
        $(target_menu).show();
    });

    $(document).on('click','.js-back-menu-link', function() {
        $('.js-mega-menu-cont').hide();
        $('.js-mega-menu-front').show();
        $(this).parent().parent('.inner-menu-row.dropdown-menu').removeClass('show');
    });

    //On hover on main menu item add show 2nd level dropdown menu and hide 3rd level dropdown menu 
    $('.js-dropdown > .nav-link').on('click mouseover focus', function () {
        $('.mega-menu-front').show();
        $('.js-mega-menu-cont').hide();
    });

    $(document).on('keypress','#inputsquarefeet,#estimateSqf,#blogInputSquareFeetTxt',function(event:any){
        if(event.shiftKey) {
            return false;
        }
        let keyCode = event.keyCode || event.which ;
        if(keyCode === 69 || keyCode === 101 || keyCode === 43 || keyCode === 45 || keyCode === 46) {
        return false;
        }
        let maxChars = 4;
            if ($(this).val().length > maxChars) {
                $(this).val($(this).val().substr(0, maxChars));
            }
    });
    $('.nav-links .hamburger-menu').click(function() {
        if($("#brandName").val() === 'mr-handyman') {
            $('body').toggleClass('mobile-header-fixed');
        }
    });
$(document).on('click', '.list-item.top-menu-cat', function(e:any){
    debugger;
       var dataid =  $(this).attr('data-id');
       makeSubmenuCatActive(dataid);
})
$(document).on('click', '.inner-list-item.sub-menu-cat', function(e){
    debugger;
       var dataid =  $(this).attr('data-id');
       makeSubmenuCatActive(dataid);
})
   
   function makeSubmenuCatActive(dataId:any){
       let data_target:any = $('.list-item.top-menu-cat[data-id="'+dataId+'"]').children().attr('data-target');
       $('.dropdown-menu.megamenu').find(`#dsk-menu-${data_target}`).hide();
       $('.inner-list-item.sub-menu-cat').removeClass('active');
       $('.inner-list-item.sub-menu-cat[data-id="'+dataId+'"]').addClass('active');
       $('.sub-menu-right.innercat-right-submenu').removeClass('show');
       $('.sub-menu-right.innercat-right-submenu[data-id="'+dataId+'"]').addClass('show');
   }
   

});

//on Key pressbutton open Inner menu Start
function myFunction(event:any) {
    if (event.which == 13 || event.keyCode == 13) {
        (document.getElementsByClassName("mega-menu-links") as any).style.display = 'block';
        return false;
    }
    return true;
}
//on Key pressbutton open Inner menu End

const smallDevice = window.matchMedia("(max-width: 991px)");

smallDevice.addListener(handleDeviceChange);

function handleDeviceChange(e:any) {
    if (e.matches) {
        $(".js-mobile-icon .search-button").on("click", function(e) {
            $(this).parent().siblings(".mob-header-search").addClass('open-search');
            $(this).parent().next().find(".form-control").focus();
        });
        $(".js-close-search").on("click", function() {
            $(".mob-header-search").removeClass('open-search');
            $('.search-button').focus();
        });

        $(document).on("click", ".header-classic .nav-item > .nav-link", function() {
            $(".dropdown").removeClass("active");
            $(this).parent().addClass("active");
        });

        // $(document).on("click", ".header-classic .dropdown-menu .col-section > button.arrow-right-link", function() {
        //     $(this).parent().addClass("active")
        // });

        $(document).on("click", ".js-backMenu", function(c) {
            c.preventDefault();
            $(".js-dropdown").removeClass("active");
            $(".js-dropdown").removeClass("show");
            $(".megamenu").removeClass("show");
        });

        $(document).on("click", ".js-backMenuInner", function(c) {
            c.preventDefault();
            $(".js-dd-inner").removeClass("active");
        });

        $(document).on("click", "button.arrow-right-link", function() {
            $(this).parent(".js-dd-inner").addClass("active");
        });

         //for Top most inner wrapper
         $(document).on("click", ".list-item", function() {
            $(this).parent().siblings('.mega-menu-links').find('.inner-menu-row').addClass('show');
        });

        $(document).on("click", ".js-back-menu", function(c) {
            c.preventDefault();
            $(".js-dropdown").removeClass("active");
            $(".js-dropdown").removeClass("show");
            $(".megamenu").removeClass("show");
        });

        $(document).on("click", ".js-back-menu-inner", function(c) {
            c.preventDefault();
            $(".js-dd-inner").removeClass("active");
        });
        
    }
}

 //On hover on main menu item add show 2nd level dropdown menu and hide 3rd level dropdown menu 
$(document).on('click mouseover focus','.js-dropdown > .nav-link', function () {
    $('.mega-menu-front').show();
    $('.js-mega-menu-cont').hide();
});

$(document).on("click", ".list-item", function(this:any) {
    let mrhSidebar = $(this).parents('.sidebar-left').hasClass('sidebar-left');
    if(!mrhSidebar){
        $('.js-mega-menu-cont').hide();
        var id = $(this).children('.js-mega-menu-btn').attr('data-target');
        $(this).parent().hide();
        $(this).parent().siblings('.mega-menu-links').find('#'+id).show();
    }
});
// Run it initially
handleDeviceChange(smallDevice);


$(document).on('click','.close-btn.js-close-menu',function(){
    $('#navbar-classic').removeClass('show');
});

// Run it initially


//new code
$('.js-backMenu-link').on('click', function() {
    $('.js-mega-menu-cont').hide();
    $('.js-mega-menu-front').show();
    $(this).parent().parent('.inner-menu-row.dropdown-menu').removeClass('show');
});

// // Hover events
$(document).on( 'mousemove', function(e:any) {
  if($("#brandName").val() === 'mr-handyman') {
    var $target = $(e.target);
    
    if( $target.hasClass('search-box') || $target.parents('.search-box').length ) {
        return;
    }
    
    if( $target.hasClass('active-sub-menu-open') || $target.parents('.active-sub-menu-open').length ) {
        return;
    }
    
    if( $target.hasClass('sub-menu-open') ) {
        $('.active-sub-menu-open').removeClass('active-sub-menu-open');
        $target.addClass('active-sub-menu-open');
        
        return;
    }
    
    if( $target.parents('.sub-menu-open').length ) {
        $('.active-sub-menu-open').removeClass('active-sub-menu-open');
        $target.parents('.sub-menu-open').addClass('active-sub-menu-open');
        
        return;
    }
    $('.active-sub-menu-open').removeClass('active-sub-menu-open');
  }
});
    //For focus on press Enter button at dropdown inner wrapper
    $(document).on("click", ".list-item", function() {
        if($("#brandName").val() === 'mr-handyman') {
        $(this).parent().siblings('.mega-menu-links').find('.menu-col .col-list li:first-child .dropdown-item').focus();
        }
    });

    //Header Submenu 
    $(document).on('click', '.header-classic .navbar-nav .dropmenu .mega-menu-links .sub-menu-left ul.inner-menu-list li a', function(e){
        if($("#brandName").val() === 'mr-handyman') {
        var $this = jQuery(this);
        var $href = $this.attr('href');
        if( $($href).length ) {
            e.preventDefault();
            
            $('.header-classic .navbar-nav .dropmenu .mega-menu-links .sub-menu-left ul.inner-menu-list li').removeClass('active');
            $this.parent().addClass('active');
            
            $('.header-classic .navbar-nav .dropmenu .mega-menu-links .sub-menu-right').removeClass('show');
            $($href).addClass('show');
        }
    }
    });
    $('.box-tiles-col .brand-btn').hover(function() {
        $(this).parents('.box-tiles-col').toggleClass('hover');
    });
    $('a.box-tiles-col').hover(function() {
        $(this).toggleClass('hover');
    });
    $(".services-list-section .read-more a").click(function(){
        $(".our_approach .more-content").slideDown(250);
        $(this).parents(".read-more").hide();
    });
    $(document).ready(function(){
        $('.secondary-btn.claim-offer, .secondary-btn.stack-btn').click(function(e){
            window.objOSGlobal.requestBodyValues.title = "";
            e.preventDefault(); // Prevent the default action of the link
            // Find the closest .sp-offers-item and then the .sp-offer within it
            var offerText = $(this).closest('.sp-offers-item').find('.sp-offer').text();
            window.objOSGlobal.requestBodyValues.OfferText = offerText;
            var offerTextDesc = $(this).closest('.sp-offers-item').find('.sp-offer-desc').text();
            if(offerText !=="" && offerTextDesc !=="" ){
                window.objOSGlobal.requestBodyValues.title = "Special offer: " + offerText.toString() + ", "+offerTextDesc.toString();
            }
        });
        

        const accordianCardContentArray = $('.accordian-section .card .card-body .body-text').toArray();
        if(accordianCardContentArray?.length){
            accordianCardContentArray.forEach(element => {
                if ($(element).hasClass('col-md-12')) {
                    element.querySelectorAll('p').forEach(p => {
                        p.style.maxWidth = 'none';
                    });
                }
            });
        }
        // Check if #assetPath and #localWeblocationId elements exist and have values
        const assetPathElement = $('#assetPath');
        const localWeblocationIdElement = $('#local_weblocationId');
        const assetPath = assetPathElement.length ? assetPathElement.val() : null;
        const localWeblocationId = localWeblocationIdElement.length ? parseInt(localWeblocationIdElement.val(), 10) : null;

        // Safe check for assetPath and localWeblocationId
        if ((assetPath?.includes("/Services/") || (assetPath?.match(/\/Blogs?\//))) &&
            (localWeblocationId === 10370 || localWeblocationId === 18576))
        {
            const regex = /five star painting/gi;
            // Replace text within #main while preserving img src attributes
            $('#main').contents().each(function ()
            {
                if (this.nodeType === Node.TEXT_NODE)
                {
                    // Safe check for text nodes and replace 'Five Star Painting' with 'ProTect Painter'
                    this.nodeValue = this.nodeValue.replace(regex, "ProTect Painter");
                } else if (this.nodeType === Node.ELEMENT_NODE && !$(this).is('img'))
                {
                    // Safe check for element nodes that are not images
                    $(this).html($(this).html().replace(regex, "ProTect Painter"));
                }
            });

            // Change the page title if it contains 'Five Star Painting'
            if (document.title.match(regex))
            {
                document.title = document.title.replace(regex, "ProTect Painter");
            }
        }
    });

    window.addEventListener("load", function() {
        function toggleContent(showMore: boolean) {
            // Check if elements exist before manipulating classes
            if ($('.cardHidden').length && $('.readMoreButton').length && $('.readLessButton').length) {
                $('.cardHidden').toggleClass('d-none', !showMore);
                $('.readMoreButton').toggleClass('d-none', showMore);
                $('.readLessButton').toggleClass('d-none', !showMore);
            }
        }
    
        $('#readMoreButton').on('click', function() {
            toggleContent(true); // Show more content
        });
    
        $('#readLessButton').on('click', function() {
            toggleContent(false); // Show less content
        });
    });
    
