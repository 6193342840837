import {redirectZipCode} from '../util/redirectZipCode';
import {servicesListDisplayModal} from "../util/servicesListDisplayModal"
import {checkZipLocalSite} from '../util/checkZipLocalSite';
import { ValidateZipPostalCode } from "../util/ValidateZipPostalCode";
import { GetLocateLocation } from "../util/GetLocateLocation";
import {defaultCallFunction} from '../util/defaultCallFunction';
import {brandWeServe} from '../services/brandWeServe';
import {GetReferenceDetails} from '../util/GetReferenceDetails';
import {validateLocalZipPostalCode} from './validateLocalZipPostalCode';
import {locateAddressBumper} from './locateAddressBumper';
import {serviceEntryBtn} from './serviceEntryBtn';
import {$} from '../third-party-imports';
import {getStateFun} from '../util/getStateFun';
import {FspSchedulerFlow} from '../util/FspSchedulerFlow';
import {checkCroEnhanceZipEntryModal,checkStringEquals,fullAddressRoundRobinApi, getFullAttributeApi, isZipCA, mrh_OptOsFlow, mrh_modalHeaderOneOrTwo, plgFormValidation, startLoader, stopLoader, validateZipCode} from '..';
import { getDefaultHeaderLogo } from '../util/get-default-header-logo';
import { getBrandJsonValue, servieModal } from '../util/get-brands-json';
import { removeClassForUpdateFlow } from '../util/updated-opus-online-scheduler-flow';
import { plgSchedulerFlow } from './plg-scheduler-flow';
import { guy_OptOsFlow } from './guy-os-flow/guy-opt-os-flow';
import { fsp_OptOsFlow } from './fsp-os-flow/fsp-opt-os-flow';
import { fspFormValidation } from './fsp-os-flow/fsp-form-validation';
import { asv_OptOsFlow } from './asv-os-flow/asv-opt-os-flow';
import { asvFormValidation } from './asv-os-flow/asv-form-validation';
import { removePreFilledData, removeProgressBarOnLocalHelp } from './common-os/remove-prefilled-data';
import { hereMapScriptLoad, nearBySearchLocation } from '../util/near-by-location';
import { referAFriendPage } from '../util/refer-a-friend';
import { commonProgressBar } from './common-os/common-progress-bar';
import { guyPreFilledData } from './guy-os-flow/guy-pre-filldata-remove';
import { commercialMlycroFlow, croOnlineModalFlow, generateCroLead } from './cro-os-flow/cro-os-flow';
import { irvingrewards} from '../util/irvingRewards';
import { contactPortland } from '../util/contact-portland';
import { mraDefaultCall, mraOsflowSchenario } from './mra-os-flow/mra-os-flow';
import {add_custEv_LocalDataLayer, addDataToNotes, getLocalization, showOfferAttachedText} from '../util/util';
import { asvPreFilledData } from './asv-os-flow/asv-pre-filldata-remove';
import { setUtmAttributes } from '../util/utm-campaign';
import { getLocalDynamicMenu } from '../util/get-service-list';

//import { mollyMaidServicesList } from './mollyMaidServicesList';
let showProfile:any = true;
let Devicedetails:any = navigator.userAgent;
let enterPressed:any = false;
export function initialCallFunction (){  

    // var brandUrl:any  = window.location.href.toLowerCase();
    // refer a friends */
    window.objOSGlobal.requestBodyValues.fsaLead = true;
    if($("#conceptCode").val() ==="MRR" && $("#countryCode").val() ==="ca"){
        hereMapScriptLoad();
    }
    setUtmAttributes();

    referAFriendPage();    // var brandUrl:any  = window.location.href.toLowerCase();
    if($("#brandName").val() == "mr-appliance"){
        mraOsflowSchenario();
    }else if($("#brandName").val() == "molly-maid"){
        let checkModifier = window.location.href.includes('commercial');
        if(checkModifier){
            commercialMlycroFlow();
        }else{
            croOnlineModalFlow();
        }
    }else if($("#brandName").val() == "portland-glass"){
        if($("#irvingrewardsform").length>0){
            irvingrewards();
        }
        if($("#plgcontactform").length>0){
            contactPortland();
        }
        plgSchedulerFlow();
        GetReferenceDetails();
        plgFormValidation();
    }else if($("#brandName").val() === 'mr-handyman' || $("#brandName").val() === 'my-handyman'){
        mrh_modalHeaderOneOrTwo();
        GetReferenceDetails();
    }else if($("#brandName").val() === 'grounds-guys'){
        guy_OptOsFlow();
    }else if($("#brandName").val() === 'five-star-painting' || $("#brandName").val() === 'protect-painters'){
        fsp_OptOsFlow();
        fspFormValidation();
}else if($("#brandName").val() === 'mr-rooter'){
        removeProgressBarOnLocalHelp();
        GetReferenceDetails();
    }else if($("#brandName").val() === 'aire-serv'){
        asv_OptOsFlow();
        asvFormValidation();
    }else{
        // other brands except MRA and MLY start
        $('.js-next-btn').click(function(this:any) {
            document.getElementById("js-schedulerDialog").scroll(0, 0);
            let nextId = $(this).parents('.tab-pane').next().attr("id");
            if (nextId == "stepfour") {
                var pageType = $('#pageType').val();
                if(pageType=='servicePage'||pageType=='categoryPage'){
                    return;
                 }
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
            } else{
                $('.scheduler-modal .modal-content').removeClass('banner-wizard');
            }
            if(nextId =="step5"){
                checkZipLocalSite(nextId);
            }else{
                $('[href="#' + nextId + '"]').tab('show');
            }
            return false;
        })
        $('.js-previous-btn').click(function(this:any) {
            getDefaultHeaderLogo();
            let conceptCode:any  = $("#conceptCode").val();
            let prevId = $(this).parents('.tab-pane').prev().attr("id");
            if(window.objOSGlobal.requestBodyValues.IsLeadOnly){
                if(prevId =='step2'){
                    if(sessionStorage.getItem('serCatBack')){
                      $('.scheduler-modal .modal-content').addClass('banner-wizard');
                      $('.tab-pane.fieldset').removeClass('active');
                      $('.zip-entry-screen').addClass('active');
                      sessionStorage.removeItem('serCatBack')
                      sessionStorage.setItem('servicePageClick','true');
                    }else if(sessionStorage.getItem('serCatBtnBack')){
                       $('.scheduler-modal .modal-content').addClass('banner-wizard');
                       $('.tab-pane.fieldset').removeClass('active');
                       $('.service-list-screen').addClass('active');
                   }else{
                      $('.tab-pane.fieldset').removeClass('active');
                      if(conceptCode =='FSP' || servieModal.enable){
                        if(sessionStorage.getItem("isAddressbumper") === "true"){
                        $('.address-bumper-screen').addClass('active');
                        $(".address-bumper-screen .back").show();
                        $('.schedulerModalLabel').addClass('d-none');
                        $('.schedulerModalLabel').empty();
                        sessionStorage.setItem("isAddressbumper", "false");
                        }else {
                        $('.schedulerModalLabel').addClass('d-none');
                        $('.schedulerModalLabel').empty();
                        $('.zip-entry-screen').addClass('active');
                        }
                      }
                      else{
                        $('.service-search-screen').addClass('active');
                      }
                      $('.scheduler-modal .modal-content').addClass('banner-wizard');
                    }
                }else{
                    $('[href="#' + prevId + '"]').tab('show');
                }
                return;
            }
            if(prevId=="step1"){
                if(sessionStorage.getItem('serCatBack')){
                    $('.scheduler-modal .modal-content').addClass('banner-wizard');
                    $('.tab-pane.fieldset').removeClass('active');
                    $('.zip-entry-screen').addClass('active');
                    sessionStorage.removeItem('serCatBack')
                    sessionStorage.setItem('servicePageClick','true');
                }else if(sessionStorage.getItem('serCatBtnBack')){
                    $('.scheduler-modal .modal-content').addClass('banner-wizard');
                    $('.tab-pane.fieldset').removeClass('active');
                    $('.service-list-screen').addClass('active');
                }else if(conceptCode =='FSP' || servieModal.enable){
                        let schedule = localStorage.getItem('home-scheduler-btn');
                        if(schedule == 'true'){
                            localStorage.removeItem('home-scheduler-btn');
                            $('.scheduler-modal .modal-content').addClass('banner-wizard');
                            $('#schedulerWizard button.close').trigger('click');
                        }else{
                        $('.tab-pane.fieldset').removeClass('active');
                        $('.scheduler-modal .modal-content').addClass('banner-wizard');
                        $('.schedulerModalLabel').addClass('d-none');
                        $('.schedulerModalLabel').empty();
                        if(sessionStorage.getItem("isAddressbumper") === "true"){
                        $('.address-bumper-screen').addClass('active');
                        $(".address-bumper-screen .back").show();
                        sessionStorage.setItem("isAddressbumper", "false");
                        }else {
                        $('.zip-entry-screen').addClass('active');
                        }
                        }
                }else{
                    $('.zip-entry-screen').removeClass('active');
                    $('.scheduler-modal .modal-content').addClass('banner-wizard');
                    $('[href="#' + prevId + '"]').tab('show');
                }
            }else{
                $('[href="#' + prevId + '"]').tab('show');
            }
            return false;
        })
        $('a[data-toggle="tab"]').on('shown.bs.tab', function(e:any) {
            //update progress
            let step = $(e.target).data('step');
            let percent:any = ((parseInt(step) / 6) * 100).toFixed(2);
            let isLeadOnly = window.objOSGlobal.requestBodyValues.IsLeadOnly;
            //e.relatedTarget // previous tab
            switch (step) {
                case 1:
                    percent = 0;
                    $('.progress-bar').css({ width: percent + '%' });
                    break;
                case 2:
                    percent = 3.2;
                    $('.progress-bar').css({ width: percent + '%' });
                    break;
                case 3:
                    percent = isLeadOnly ? 3.2 : 27;
                    $('.progress-bar').css({ width: percent + '%' });
                    break;
                case 4:
                    percent = isLeadOnly ? 36 : 52;
                    $('.progress-bar').css({ width: percent + '%' });
                    break;
                case 5:
                    percent = isLeadOnly ? 67 : 76;
                    $('.progress-bar').css({ width: percent + '%' });
                    break;
                case 6:
                    percent = isLeadOnly ? 100 : 82 ;
                    $('.progress-bar').css({ width: percent + '%' });
                    break;
                case 7:
                    percent = 100;
                    $('.progress-bar').css({ width: percent + '%' });
                    $(".progress").addClass("d-none");
                    $(".progressbar").addClass("d-none");
                    $(".modal-header-two").addClass("d-none");
                    $(".modal-header-one").addClass("d-none");
                    break;
            }
        })

        // other brands except MRA and MLY end
    }
        $('.first').click(function() {
            $('#schedulerWizard a:first').tab('show')
        })
        if ($('.js-nei-popover').length) {
            try{
            $('.js-nei-popover').popover({
                html: true,
                title: function() {
                    return $('.popover-title').html();
                },
                content: function() {
                    return $('.popover-content').html();
                }
            });
        } catch(error) {
            console.log("error while accessing popover",error);
        }
            $('.js-nei-popover').on('click', function() {
                $("body").addClass('stamp-popover-open');
            });
        }
        $(document).on('click', '.js-close-icon', function() {
            try{
            $('.js-nei-popover').popover('hide');
            } catch(error) {
                console.log("error while hiding popover",error);
            }
            setTimeout(function() {
                $("body").removeClass('stamp-popover-open');
            }, 200);
        });
        $(document).on("click", ".popover", function(e:any) {
            e.stopPropagation();
            try{
            $('.js-nei-popover').popover('hide');
            } catch(error) {
                console.log("error while hiding popover",error);
            }
        });
        if ($('.js-service-list-control').length) {
            $(document).on('keyup', '.js-service-list-control', function(this:any,) {
                let listLength = ($(this).val() as any).length;
                if (listLength >= 1) {
                    $('.js-services-list-dd').show();
                } else {
                    $('.js-services-list-dd').hide();
                }
            });
        }
        if ($('#locationSearch_change_loc').length) {
            $(document).on('keyup', '#locationSearch_change_loc', function(this:any) {
                let listLength = ($(this).val() as any).length;
                if (listLength >= 1) {
                    $('.js_services_list_dd_change_loc').show();
                } else {
                    $('.js_services_list_dd_change_loc').hide();
                }
            });
        }
        $("input[name='chkFrequesy']").click(function(){
            if ($("#inputOneTime").is(":checked")) {
                $("#js-one-time-data").show();
                $("#js-recurring-data").hide();
            }
            if ($("#inputRecurring").is(":checked")) {
                $("#js-recurring-data").show();
                $("#js-one-time-data").hide();
            }
        });
    $('#js-schedulerDialog  .close').click(function(){
        let brandJsonItems = window?.objOSGlobal?.requestBodyValues?.brandJsonGlobalObj;
        let checkCroleadEnable = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match || false ;
        localStorage.removeItem("zipcode");
        $('.address-details-screen h2').text('Service Address');
        $('.address-details-screen h5').remove();
        $(".scheduler-slide .tab-pane ").each(function(this:any) {
            $(this).removeClass('active');
        });
        sessionStorage.removeItem('serCatBtnBack');
        $(".scheduler-slide ul.progressbar li a").removeClass("active");
        $('.progress-bar').css({ width: '0%' });
        $('.selected-date-txt').text("");
        $('.selected-date').addClass('d-none');
        // $('.calendar-screen .next').addClass('disble-btn').prop('disabled',true);
        $('p.call-details-text').addClass('d-none');
        $('#giftStep1.js-gift-tab-pane').addClass('active');
        if(checkCroleadEnable){
            $('.progress, .progressbar').hide();
            $(".modal-header-one").addClass('d-none');
            $(".modal-header-two").removeClass('d-none');
        }
        if($('#conceptCode').val() == "GUY" || $('#conceptCode').val() == "FSP"){
            $('.progress, .progressbar').hide();
            $(".modal-header-one").addClass('d-none');
            $(".modal-header-two").removeClass('d-none');
        }
        if($('#conceptCode').val()?.toLowerCase() === "mly"){
            let currentModalId = $('.tab-pane.fieldset.active').attr("id");
            if(currentModalId ==="step2"){
                window.onbeforeunload = null;
                generateCroLead();
            }
            $(".tab-pane.fieldset").removeClass('active');
            $(".modal-header").hide();
            $(".modal-header-two").show();
            $(".localized-progress").hide();
        }
        $(".zip-entry-screen").addClass("active");
        $(".mm-donation-form #step1").addClass("active");
    });
    $('#no-service-close-btn').click(function(this:any,e:any){
        if(!$("#no-service-close-btn").hasClass('try-again')){
            $('#js-schedulerDialog  .close').click();
        }
    });
    $('#service-entry-btn').off().on('click',async function(e:any){
        let conceptCode = $('#conceptCode')?.val();  
        let isWebSite = $("#countryCode")?.val()?.toLowerCase();
        let brandJsonItems = window?.objOSGlobal?.requestBodyValues?.brandJsonGlobalObj;
        const croleadNoZipEnable = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match || false ;
       
        if(conceptCode?.toLowerCase() ==="mrr" && isWebSite ==="ca" || croleadNoZipEnable){
            if($(".search-services-form")?.valid()){
                let postal:any = $('#locationSearch')?.val()?.toUpperCase() || $('.banner-sec input[name="cta-ser-search"]')?.val()?.toUpperCase();
                FsaOSFlowScenarion(postal?.trim());
            }
        }
        else if($(".search-services-form")?.valid()){
                startLoader();
                e.preventDefault();
                let regexp:any = /android|iphone|kindle|ipad/i;
                let isMobileDevice = regexp.test(Devicedetails);
                isMobileDevice && await getStateFun();
                serviceEntryBtn();
            }
        if(conceptCode?.toLowerCase() ==="mra" || conceptCode?.toLowerCase() ==="plg"){
            showOfferAttachedText();
        }
    });
    $("#locationSearch").keydown(function(e:any){
        if($(".search-services-form").valid()){
            let locationSearch:any =  $('#locationSearch').val()
            if(validateLocalZipPostalCode(locationSearch)){
                var errorElement = document.getElementById("locationSearch-error");
                if(errorElement){
                    errorElement.setAttribute("aria-label", "");
                } 
            window.objOSGlobal.requestBodyValues.PostalCode = locationSearch;
                if (e.which === 13)
                {
                    enterButtonPress(e);
                    $("#service-entry-btn").click();
                }
            }
        }
    });
    defaultCallFunction();
    $("#header_btn_zipcode, #main_btn_zipcode_change_loc, #header_btn_zipcode_mobile").off().on('click',function(this:any,e:any){
        e.preventDefault();
        var buttonId = $(this).attr("id");
        var zipValue:any = buttonId == "main_btn_zipcode_change_loc" ? $('.location-search input[name="locationSearch_change_loc"]').val().trim() : $(this).siblings('input').val();
        let domainName = location.hostname.toLowerCase();    
        var isWebSite = domainName.indexOf("-ca-") === -1 && domainName.indexOf(".ca") === -1 && domainName.indexOf("-ca.") == -1 ? "us" : "ca";
        if(buttonId === "main_btn_zipcode_change_loc"){
            if(checkCroEnhanceZipEntryModal()){
                if(!$('.location-services-change').valid()){
                    return;
                }
            }else{
                if(!validateZipCode()){
                    return;
                }
            }
        }
        if(buttonId === "header_btn_zipcode_mobile"){
            if(ValidateZipPostalCode($("#zipcode_mobile").val(),"desk",'')){
                GetLocateLocation("desk",true);
                return;
            }
        }
        if (isWebSite == 'ca'){
            zipValue = zipValue.indexOf(" ") == -1 ? zipValue.replace(/^(.{3})(.*)$/, "$1 $2") : zipValue;
        }
        if(typeof(zipValue) !== 'undefined'){
            localStorage.setItem('zipcode',zipValue);
            $('.address-bumper-screen input[name="inputZipCode1"]').val(zipValue);
        }
        locateAddressBumper();
        var thisid = $(this).attr('id');
        if(thisid == "main_btn_zipcode_change_loc"){
        $('.address-bumper-screen input[name="inputZipCode1"]').val($("#locationSearch_change_loc").val().trim());
             GetLocateLocation("desk");
        }
        else{
            if(ValidateZipPostalCode($("#zipcode").val(),"desk",'')){
                GetLocateLocation("desk");
            }
        }
    });
    $("[name='zipcode']").keydown(function(e:any){
        if(e.which === 13){
            e.preventDefault();
            $("#header_btn_zipcode, #header_btn_zipcode_mobile").click();
        }
    });

    $("#main_mob_btn_zipcode").click(function(e:any){
            e.preventDefault();
            if(ValidateZipPostalCode($("#inputLocal-zip").val(),"mob",'') && $("#inputLocal-zip").valid()){     
                locateAddressBumper();
                GetLocateLocation("mob");
            }
    });
    $('.col-btn .desktop-btn .primary-btn[data-target="#js-schedulerDialog"]').click(async function(e:any){
            e.preventDefault();
            $('.scheduler-modal .modal-content').addClass('banner-wizard');
            if($('#conceptCode').val()=='FSP'){
                sessionStorage.removeItem("exitOfferNote");
            }
            // await getStateFun();
            if($('#conceptCode').val()=='MLY'){
                removeClassForUpdateFlow();
            }
    });
    $('.request-mobile-sec.sticky-header .primary-btn[data-target="#js-schedulerDialog"]').click(async function(e:any){
        e.preventDefault();
        $('.scheduler-modal .modal-content').addClass('banner-wizard');
        // await getStateFun();
        if($('#conceptCode').val()=='MLY'){
            removeClassForUpdateFlow();
        }
});
    $(document).off('change').on('change', '.service-details-screen select', function (this:any){
        window.objOSGlobal.paramsUI.serviceDetailsSelectedText = "";
        var selectedItem = $(this).children("option:selected").val();
        if($('#conceptCode').val()=='MRA' || $('#conceptCode').val() =='FSP'){
            // window.objOSGlobal.requestBodyValues.LeadSourceId = selectedItem;
            window.objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = selectedItem;
            window.objOSGlobal.paramsUI.serviceDetailsSelectedText = "Lead Source: " + $(this).children("option:selected").text();
        }else{
if($('#conceptCode').val()!=='MRR' || $('#conceptCode').val()!=='GUY'){
            window.objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = selectedItem;
            window.objOSGlobal.paramsUI.serviceDetailsSelectedText = "Lead Source: " + $(this).children("option:selected").text();
            //     if($('#referenceDetails').attr('required')?.length){
            //     if(selectedItem > 0){
                //         $('.service-details-screen #sd_referenceDetails_error').css('display', 'none');
                //         $(".service-details-screen .next").removeClass("disble-btn").prop("disabled", false);
            //     }else{
                //         $('.service-details-screen #sd_referenceDetails_error').css('display', 'inline-block');
                //         $(".service-details-screen .next").addClass("disble-btn").prop("disabled", true);
            //     }
        //  }
        }
}
    }); 
    
    $(document).on('click','.header-destkop .locate-form .find-click, .desktop-header .locate-form .find-click',function(){ 
          $(".find-click").addClass("d-none");
          $(".zip-code-loc").removeClass("d-none");
    });
    $(document).on('click','.header-mobile .locate-form .find-click',function(){ 
        $(".find-click").addClass("d-none");
        $(".zip-code-loc").removeClass("d-none");
  });
    
    $(document).on('click','.location-section a',function(){
            $('.progress, .progressbar').hide();
            const cptCode = $("#conceptCode").val();
            $('#js-schedulerDialog').modal('show');
            $(".zip-entry-screen").removeClass("active");
            $("#stepone_change_location_local").addClass("active");
            $('.scheduler-modal .modal-content').addClass('banner-wizard');
            if(cptCode ==="MHM" || cptCode ==="HMC"){
                $('.modal-header-two').hide();
                $('.modal-header-one').show();
            }
    });
    $(document).off('change').on('change', 'input[name="inputZipCode"], input[name="mrhZipCode"]', function ()
    {
        const zipCode = $(this).val().trim();
        const country: string = $("#countryCode").val() as string;
        // Check if the country is US and the ZIP code is Canadian
        const shouldToggle = checkStringEquals(country, 'us') && isZipCA(zipCode);
        // Toggle options based on the condition
        if (shouldToggle)
        {
            $('#optin-us').toggleClass('hidden', true); // Hide US option if toggling
            $('#optin-ca').toggleClass('hidden', false); // Show CA option if toggling
        }
    });
    
    $("#js-schedulerDialog").on("hidden.bs.modal", function () {
        if(!$("#no-service-close-btn").hasClass('try-again')){
            $('#js-schedulerDialog  .close').click();
            let conceptCode:any  = $("#conceptCode").val();
            let countryCode:any = $("#countryCode").val();
            let brandJsonItems = window?.objOSGlobal?.requestBodyValues?.brandJsonGlobalObj;
            let croleadNoZipEnable = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match || false ;
            if(conceptCode?.toLowerCase() ==="mrr" && countryCode?.toLowerCase() ==="ca" || croleadNoZipEnable){
                let phone = $($(".anchor_desktop_phone_number").clone()[1]).children().remove().end().text().trim();
                if(phone){
                    $('.mm_modal-call a.call-icon').attr('href',"tel:"+phone);
                    let childEle = $('.mm_modal-call a.call-icon').children();
                    $('.mm_modal-call a.call-icon').text(' '+phone).prepend(childEle);
                }
            }
        }
    });
    
    $(document).ready(function() {
        const header = $(".header-classic .navbar");
        let lastScrollTop = 0;
        $(window).scroll(function(this: any) {
            //Mobile header icon visibility
            var scroll = $(window).scrollTop();
            if (scroll >= 50) {
                //clearHeader, not clearheader - caps H
                $(".request-mobile-sec").addClass("sticky");
            }
            else{
                $(".request-mobile-sec").removeClass("sticky");  
            }
            //Desktop 
            let scrollTop = $(this).scrollTop();
            if (scrollTop < lastScrollTop) {
                header.addClass("sticky");
            } else {
                header.removeClass("sticky");
            }
            lastScrollTop = scrollTop;
            if ($("#brandName").val() === 'mr-rooter')
            {
                let nav_height = $('.request-mobile-sec.sticky-header>ul').height() + Number($('.request-mobile-sec.sticky-header>ul').css('padding-top').split('p')[0]);
                let scroll = $(window).scrollTop();
                let width = $(window).width();
        
                if (width < 992)
                {
                    if (scroll < nav_height)
                    {
                        $('body').removeClass('mobile-call-footer-sticky-active');
                        $('.mobile-call-footer-sticky-container').removeClass('mobile-call-footer-sticky');
                    } else
                    {
                        $('body').addClass('mobile-call-footer-sticky-active');
                        $('.mobile-call-footer-sticky-container').addClass('mobile-call-footer-sticky');
                    }
                } else
                {
                    $('body').removeClass('mobile-call-footer-sticky-active');
                    $('.mobile-call-footer-sticky-container').removeClass('mobile-call-footer-sticky');
                }
            }

        });
        // for CTA Button
    }); 
    
    $('.clicktocall').click(function(){
        const dniNumber = this.getAttribute("href");
        window.location.href = dniNumber;
    })  
    $('.schedule-hero-btn').click( async function() {
        if($('#conceptCode').val()=='FSP'){
            sessionStorage.removeItem("exitOfferNote");
        }
        var heroButtonHidden = $('.schedule-hero-btn').is(":hidden");
        var validateHeroInputBox = $('#inputHeroZipCode').valid();
        if(heroButtonHidden || validateHeroInputBox){
            const heroButton = $('.schedule-hero-btn');
            if (heroButton.attr("data-target") === undefined){
                heroButton.attr("data-target", "#js-schedulerDialog")
            }
            localStorage.setItem('home-scheduler-btn','true');
            var postal:any = $('#inputHeroZipCode').val() ? $('#inputHeroZipCode').val().toUpperCase() : localStorage.getItem('zipcode');
            postal = postal.trim();
            if(!redirectZipCode(postal)){
                // Ensure all event listeners for #js-schedulerDialog are removed
                return;
            }
            await getBrandJsonValue();
            $('.tab-pane.fieldset').removeClass('active');
            if(postal){
                let isWebSite = $("#countryCode").val()?.toLowerCase();
                let conceptCode:any  = $("#conceptCode").val();
                let brandJsonItems = window?.objOSGlobal?.requestBodyValues?.brandJsonGlobalObj;
                const croleadNoZipEnable = brandJsonItems[0]?.enable_cro_enhance_lead_no_zip_match || false ;
               
                if(conceptCode?.toLowerCase() ==="mrr" && isWebSite ==="ca" || croleadNoZipEnable){
                    FsaOSFlowScenarion(postal?.trim());
                }else{
                    if(!redirectZipCode(postal)){
                        return;
                    }
                    if (isWebSite == 'ca'){
                            postal = postal.indexOf(" ") == -1 ? postal.replace(/^(.{3})(.*)$/, "$1 $2") : postal;
                    }
    
                    window.objOSGlobal.requestBodyValues.serviceType = '4';
                    window.objOSGlobal.requestBodyValues.PostalCode = postal;
                    localStorage.setItem('zipcode',postal);
                    if(conceptCode.toUpperCase() =='FSP' || servieModal.enable){
                        startLoader();
                        await getStateFun();
                        FspSchedulerFlow(postal);
                        return;
                    }
                    servicesListDisplayModal(postal)
                }
            }else{
                $('.zip-entry-screen').addClass('active');
                $('#js-schedulerDialog').modal('show');
            }
          //await getStateFun();
        } else {
            $('.schedule-hero-btn').removeAttr("data-target");
        }
      });
      $("#inputHeroZipCode").keydown(function(e:any){
          let inputHeroZipCode:any = $('#inputHeroZipCode').val();
          var validateHeroInputBox = $('#inputHeroZipCode').valid();
          if(validateHeroInputBox){
          window.objOSGlobal.requestBodyValues.PostalCode = inputHeroZipCode;
              if (e.which === 13)
              {
                if (!enterPressed)
                {
                    e.preventDefault();
                    enterPressed = true;
                    setTimeout(function ()
                    {
                        enterPressed = false;
                    }, 5000);
                    $(".schedule-hero-btn").click();
                }
            }
        }
    });
    $("#inputHeroZipCode").keyup(function(){
        if($('#inputHeroZipCode').valid()){
            $('.banner-text-sec #inputHeroZipCode').removeClass("locationerror");
            $('.banner-text-sec .field-close-icon').removeClass("locationerror");
            $('.banner-text-sec #hero-location-gps').removeClass("gps-icon-error");
        }
        else{
            $('.banner-text-sec #inputHeroZipCode').addClass("locationerror");
            $('.banner-text-sec .field-close-icon').addClass("locationerror");
            $('.banner-text-sec #hero-location-gps').addClass("gps-icon-error");
        }
    });
        
    $("#zipcode").blur(function() {
        ValidateZipPostalCode($("#zipcode").val(),"desk",'');
    });

    $("#zipcode_mobile").blur(function() {
        ValidateZipPostalCode($("#zipcode_mobile").val(),"desk",'');
    });

    // $("#inputLocal-zip").keypress(function() {
    //         ValidateZipPostalCode($("#inputLocal-zip").val(),"mob",'');
    // });
    $('*').hasClass('brands-services-list') && brandWeServe();
    $("#profile").on('click', function(){
        if(showProfile) {
            showProfile = false;
            $('.nav-container .row ul li:nth-child(4) > ul').css('display','block');
        }else {
            $('.nav-container .row ul li:nth-child(4) > ul').css('display','none');
            showProfile = true;
        }
    });
}

$('#js-recurring-data input[name="chkFrequencyMethod"]').change(function(this:any) {
    var mlyFrequency = $(this).siblings('.radio-txt').text();
    sessionStorage.setItem('MlyFrequency',mlyFrequency);     
});

$('.location-search input[name="locationSearch_change_loc"]').keypress(function(this: any, e: any){
    if (this.value === "0000" && e.which == 48){
        return false;
    }
    if ((e.keyCode == 13))  {return false;} 
});

$('.location-search input[name="locationSearch_change_loc"]').keydown(function(this: any, e: any){
    if ((e.which == 13)){
        enterButtonPress(e);
        $("#main_btn_zipcode_change_loc").click();
    } 
});
$('.progressbar li').on('keydown', function(e:any) { 
    if (e.which == 9) { 
      e.preventDefault(); 
    } 
});

function enterButtonPress(e:any){
    if (!enterPressed)
    {
        e.preventDefault();
        enterPressed = true;
        setTimeout(function ()
        {
            enterPressed = false;
        }, 5000);
    }
}
export function FsaOSFlowScenarion(postal:any){
    let nextId:any = $('.service-search-screen').next().attr("id");
    let isCountryCode = $("#countryCode").val()?.toLowerCase();
    let conceptCode:any  = $("#conceptCode").val();
    let brandName:any = $("#brandName").val();
    window.objOSGlobal.requestBodyValues.PostalCode = postal;
    let fsaPostalCode:any = postal;
    localStorage.setItem('zipcode', window.objOSGlobal.requestBodyValues.PostalCode);
    if(isCountryCode ==="ca"){
        fsaPostalCode= postal + ',canada';
    }
    if(!redirectZipCode(postal)){
        return;
    }
    startLoader();
    fullAddressRoundRobinApi(fsaPostalCode,false,()=>{
        if(!window.objOSGlobal.requestBodyValues.isFsaLead){
            getBrandJsonValue();
            getStateFun();
            window.objOSGlobal.requestBodyValues.fsaLead = true;
            getFullAttributeApi(window.objOSGlobal.requestBodyValues.WebLocationId, () =>{
                const pageType = getLocalization()
                if(pageType === "National"){
                    add_custEv_LocalDataLayer();
                }
                 if(isCountryCode ==="ca"){
                    let properPostalCode =  window.objOSGlobal.requestBodyValues.PostalCode?.replace(/ +/g, "");
                    if(properPostalCode?.length !== 6){
                        window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
                    }
                 }
                 if(window.objOSGlobal.requestBodyValues.IsLeadOnly){
                    stopLoader();
                 }
                 if(conceptCode?.toLowerCase() ==="mrr"){
                    removePreFilledData();
                    commonProgressBar();
                    getLocalDynamicMenu();
                 }else if(conceptCode?.toLowerCase()==="guy"){
                    guy_OptOsFlow();
                    guyPreFilledData(nextId);
                 }else if(conceptCode?.toLowerCase()==="mra"){
                    mraDefaultCall(nextId);
                }else if(brandName?.toLowerCase()==="mr-handyman"){
                    stopLoader();
                    mrh_OptOsFlow(nextId);
                }
                else if(brandName?.toLowerCase()==="aire-serv"){
                    stopLoader();
                    asvPreFilledData(nextId);
                    asvFormValidation();
                    getLocalDynamicMenu();
                }
            });
        }else{
            window.objOSGlobal.requestBodyValues.isFindYourLocation = false;
            nearBySearchLocation(postal);
        }
    },'');
}

const exitPopup = $("#fsp-modal");
const fsp = 'five-star-painting'
let brandName:any;
 function startTimer() {
      let initTimer = "10:00";
      const countdown = setInterval(function () {
        let splitTimer = initTimer.split(":");
        let minutes = parseInt(splitTimer[0], 10);
        let seconds = parseInt(splitTimer[1], 10);
        --seconds; 
        minutes = seconds < 0 ? --minutes : minutes;
        seconds = seconds < 0 ? 59 : seconds;
        seconds = seconds < 10 ? 0 + seconds : seconds;
        exitPopup.find(".fsp-minutes").text(minutes.toString().padStart(2, "0"));
        exitPopup.find(".fsp-seconds").text(seconds);
  
        if (minutes < 0) clearInterval(countdown);
        if (seconds <= 0 && minutes <= 0) clearInterval(countdown);
  
        initTimer = minutes + ":" + seconds;
      }, 1000);
    }
    
    function showModal() {
        if(brandName === fsp){
            $("#fsp-modal").css("display", "flex");
            $('body').css("overflow", "hidden");
            exitPopup.fadeIn(350);
            sessionStorage.setItem("cf22_3ModalSeen", "true");
            startTimer();
        }
    }
  
    function closeModal(applyDiscount = false) {
        let title = $('.fsp-modal-header').text();
        let subTitle = $('.fsp-offer').text();
        if(brandName === fsp){
            exitPopup.fadeOut(350);
            $("#fsp-modal").css("display", "none");
            $('body').css("overflow", "auto");
            if (applyDiscount) {
                window.objOSGlobal.requestBodyValues.OfferText = title + " " + subTitle;
                $('#serviceDetails #inputService').text('Please apply my 10% off discount!');
                if (!$("#js-schedulerDialog").hasClass("show")) {
                    $(`a[data-target="#js-schedulerDialog"]`).first()[0].click();
                    addDataToNotes("Exit offer: " + window.objOSGlobal.requestBodyValues.OfferText);
                }
            }
        }
    }
  
    function addContent() {
      let scheduler = $("#js-schedulerDialog");
      if (scheduler.length === 0) {
        setTimeout(addContent, 50);
        return;
      }
  
      let modalSession = sessionStorage.getItem("cf22_3ModalSeen");
      if (!modalSession) {
        sessionStorage.setItem("cf22_3ModalSeen", "false");
      }
  
      let modalOpened = modalSession === "true" ? true : false;
      let schedulerOpened = false;
      let callback = (obs:any) => {
        if (scheduler.hasClass("show")) {
          schedulerOpened = true; // User has opened the modal
        } else {
          if (schedulerOpened) {
            // User has opened the modal before, now closed
            if (modalOpened === false) {
              let locationSearch = scheduler.find("#locationSearch").val();
              if (locationSearch === "" || locationSearch !== scheduler.find(".noservice-zipcode").text()) {
                modalOpened = true;
                showModal();
                obs.disconnect();
              }
            }
          }
        }
      };
  
      let mo = new MutationObserver(callback);
      mo.observe(scheduler[0], { attributes: true, attributeFilter: ["class"] });
  
      $(".fsp-cta-link,.fsp-cta > a.desktop-request-appointment").on("click", function () {
        closeModal(true);
    });
  
      $("#fsp-modal-close").on("click", function () {
        closeModal();
      });

    //Desktop intent to exit event
    setTimeout(() => {
      $(document).on("mouseout mouseleave mouseenter mouseover", (evt:any) => {
        if (evt.clientY < 0 && evt.toElement == null && evt.relatedTarget == null && !modalOpened && brandName === fsp) {
          modalOpened = true;
          showModal();
        }
      });
    }, 1000);

    setTimeout(() => {
      //Mobile intent to exit event
      $(window).on("flick", (e:any) => {
        if (e.originalEvent.detail.direction === "up" && $(window).width() <= 787 && !modalOpened && brandName === fsp) {
          modalOpened = true;
          showModal();
        }
      });
    }, 1000);
    }
  
    $(document).ready(function () {
        brandName = $("#brandName").val().toLowerCase();
        if(brandName === fsp){
            addContent();
        }
    });
  
  // Flick Function (Mobile intent to exit)
  (function (t) {
    let lastScroll = window.scrollY;
    let lastTime = new Date().getTime();
    let triggered = false;
    let timer:any = 0;
    let threshold = t;
  
    let maxScrollSpeed = 0;
    window.addEventListener("scroll", function () {
      let currentScroll = window.scrollY;
      let currentTime = new Date().getTime();
      let deltaScroll = currentScroll - lastScroll;
      let deltaTime = currentTime - lastTime;
      let scrollDirection;
      if (deltaScroll < 0) {
        scrollDirection = "up";
      } else {
        scrollDirection = "down";
      }
      let scrollSpeed = Math.abs(deltaScroll) / deltaTime;
      if (scrollSpeed > maxScrollSpeed) {
        maxScrollSpeed = scrollSpeed;
      }
      if (scrollSpeed >= threshold) {
        if (!triggered) {
          let e = new CustomEvent("flick", { detail: { direction: scrollDirection } });
          triggered = true;
          timer = setTimeout(function () {
            triggered = false;
          }, 100);
          window.dispatchEvent(e);
        } else {
          clearTimeout(timer);
          timer = setTimeout(function () {
            triggered = false;
          }, 100);
        }
      }
  
      lastScroll = currentScroll;
      lastTime = currentTime;
    });
  })(8.5);

