import {setAlternateBrandData} from './setAlternateBrandData';
export function youtubeInit() 
{
    ytFunc();
    let webLocationId = $("#weblocationId").val();
    webLocationId = webLocationId ? webLocationId : localStorage.getItem('localWebLocationId');
    if(webLocationId) {
		setAlternateBrandData();
	}
}

function ytFunc(){
    const youtubeElements = Array.from(document.querySelectorAll( ".youtube" )) as HTMLElement[];
    if(youtubeElements.length>0){
        $('.youtube').prepend(`<div class = "play-button"></div>`);	
        for (const youtube of youtubeElements)  
        {	
            let embedCode = youtube.dataset.embed;
            const iframe = globalThis.document.createElement( "iframe" );
            iframe.setAttribute( "frameborder", "0" );
            iframe.setAttribute( "allowfullscreen", "" );
            iframe.setAttribute( "src", "https://www.youtube.com/embed/"+ embedCode +"?autoplay=0&rel=0&showinfo=0" );
            iframe.setAttribute( "loading","lazy");
            youtube.innerHTML = "";
            youtube.appendChild( iframe );
        }
    }
}